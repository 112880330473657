import buildRoutes from '../shared/buildRoutes'

export const PRODUCTS_SECTION_HASH = 'all-products'
const productRoutes = buildRoutes('products', {
  products: ({
    filter,
    scroll = true,
  }: { filter?: string; scroll?: boolean } = {}) => {
    const suffix = scroll ? `#${PRODUCTS_SECTION_HASH}` : ''
    if (!filter) {
      return suffix
    }
    return `?filter=${filter}${suffix}`
  },
  detail: ({ slug, color }: { slug?: string; color?: string } = {}) => {
    if (!slug) {
      return ''
    }
    const base = `${slug}`
    if (!color) {
      return base
    }
    return `${base}?color=${color}`
  },
})
export default productRoutes
