import Head from 'next/head'
import React, { useLayoutEffect } from 'react'

import config from '../../../core/config'
import { layerPush } from '../functions/track'
import useTrackLocation from '../hooks/useTrackLocation'

const AnalyticsScriptTag = () => {
  const GTM_ID = config('TAG_MANAGER_ID')

  useLayoutEffect(() => {
    if (!GTM_ID) {
      return
    }

    layerPush({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    })
  }, [GTM_ID])

  useTrackLocation()

  if (!GTM_ID) {
    return null
  }

  return (
    <Head key="analytics_tagmanager_script">
      <script> window.dataLayer = window.dataLayer || [] </script>
      <script
        type="text/javascript"
        src={`https://www.googletagmanager.com/gtm.js?id=${GTM_ID}`}
        async
      ></script>
    </Head>
  )
}

export default AnalyticsScriptTag
