import { createContext, useState, FC } from 'react'

interface BrandContextType {
  isShippingCountriesModalOpen: boolean
  setShippingCountriesModalOpen: (isOpen: boolean) => void
}

export const BrandContext = createContext<BrandContextType>({
  isShippingCountriesModalOpen: false,
  setShippingCountriesModalOpen: (isOpen) => {},
})

const BrandContextProvider: FC = ({ children }) => {
  const [isShippingCountriesModalOpen, setIsShippingCountriesModalOpen] =
    useState(false)

  const setShippingCountriesModalOpen = (isOpen: boolean) => {
    setIsShippingCountriesModalOpen(isOpen)
  }

  return (
    <BrandContext.Provider
      value={{
        isShippingCountriesModalOpen,
        setShippingCountriesModalOpen,
      }}
    >
      {children}
    </BrandContext.Provider>
  )
}

export default BrandContextProvider
