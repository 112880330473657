import { ShippingCountryWizard } from '@pangaea-holdings/pangaea-checkout'
import React, { useContext } from 'react'

import { BrandContext } from '../context'

const CountriesModal: React.FC = () => {
  const { isShippingCountriesModalOpen, setShippingCountriesModalOpen } =
    useContext(BrandContext)

  return (
    <ShippingCountryWizard
      isOpen={isShippingCountriesModalOpen}
      setIsOpen={(val) => setShippingCountriesModalOpen(val)}
    />
  )
}

export default CountriesModal
