import { useEffect, useState } from 'react'

import { theme as glamneticTheme } from '../design/themes/glamnetic-theme'
import { theme as zitstickaNewTheme } from '../design/themes/zitsticka-new-theme'
import { theme as zitstickaTheme } from '../design/themes/zitsticka-theme'
import { selectBrand } from '../modules/brand/selectors'
import { useTypedSelector } from '../redux/store'

export const useSelectedTheme = () => {
  // Setting the default theme to be zitsticka's since we don't have a theme for PMall itself yet
  const [theme, setTheme] = useState<any>(zitstickaTheme)

  const selectedBrand = useTypedSelector(selectBrand())

  const loadBrandTheme = async (brandName: string) => {
    setTheme(getBrandTheme(brandName))
  }

  useEffect(() => {
    if (selectedBrand) {
      loadBrandTheme(selectedBrand)
    }
  }, [selectedBrand])

  return theme
}

export const getBrandTheme = (brand: string) => {
  switch (brand) {
    case 'zitsticka':
      return zitstickaNewTheme
    case 'glamnetic':
      return glamneticTheme
    default:
      return zitstickaTheme // for now we set zitstickaTheme as the default theme
  }
}
