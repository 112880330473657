import { combineReducers, ActionCreator } from 'redux'
import { ThunkAction } from 'redux-thunk'

import { AccountActionTypes } from '../modules/account/actionTypes'
import { AccountReducerState, accountReducer } from '../modules/account/reducer'
import { BrandActionTypes } from '../modules/brand/actionTypes'
import { brandReducer, BrandReducerState } from '../modules/brand/reducer'
import { cartDrawerReducer, CartDrawerState } from '../modules/cartv2/reducer'
import { ProductsActionTypes } from '../modules/products/actionTypes'
import {
  productsReducer,
  ProductsReducerState,
} from '../modules/products/reducer'

import { CartDrawerActionTypes } from 'modules/cartv2/actionTypes'

export const rootReducer = combineReducers({
  account: accountReducer,
  products: productsReducer,
  brand: brandReducer,
  cartDrawer: cartDrawerReducer,
})

export type AppState = {
  account: AccountReducerState
  products: ProductsReducerState
  brand: BrandReducerState
  cartDrawer: CartDrawerState
}

export type AppActionTypes =
  | AccountActionTypes
  | ProductsActionTypes
  | BrandActionTypes
  | CartDrawerActionTypes

export type ThunkActionCreator<R> = ActionCreator<
  ThunkAction<R, AppState, void, AppActionTypes>
>
