const baseStyle = {
  fontFamily: 'regular',
  fontWeight: 'normal',
}

const sizes = {
  '2xl': {
    fontSize: ['1.5rem', '2.5rem', '4rem'],
    lineHeight: ['auto', '2.5rem', '4rem'],
  },
  xl: {
    fontSize: ['1rem', '2rem', '3rem'],
    lineHeight: ['auto'],
  },
  lg: {
    fontSize: ['1rem', '1.25rem', '1.5rem'],
    lineHeight: ['auto'],
  },
  md: {
    fontSize: ['0.875rem', '1rem', '1.125rem'],
    lineHeight: ['1.125rem', '1.5rem'],
  },
  sm: {
    fontSize: ['0.875rem', null, '1rem'],
    lineHeight: ['1.125rem', '1.25rem'],
  },
  xs: {
    fontSize: ['0.625rem', '0.75rem', '0.75rem'],
    lineHeight: ['auto'],
  },
}

const defaultProps = {
  size: 'md',
}
const primaryText = {
  fontWeight: 'normal',
  fontSize: sizes.md.fontSize,
  lineHeight: sizes.md.lineHeight,
  color: 'textPrimary',
}

const variants = {
  cardBody: primaryText,
  sectionLabel: { ...primaryText, fontWeight: 'semibold' },
  formBody: {
    fontWeight: 'normal',
    fontSize: sizes.sm.fontSize,
    lineHeight: sizes.sm.lineHeight,
    color: 'textPrimary',
  },
  info: {
    fontWeight: 'normal',
    fontSize: sizes.xs.fontSize,
    lineHeight: sizes.xs.lineHeight,
    color: 'textPrimary',
  },
  heroBody: {
    fontSize: ['0.625rem', '1.25rem', '1.5rem'],
    color: 'heroText',
  },
  heroTopic: {
    fontSize: ['0.625rem', '1rem', '1.125rem'],
    color: 'heroText',
  },
  helperText: {
    fontSize: ['0.625rem', '0.75rem', '0.875rem'],
    color: 'textPrimary',
  },
  marqueeText: {
    fontFamily: 'bold',
    lineHeight: '2.25rem',
    textShadow: '0 0 .25em currentColor',
    textTransform: 'uppercase',
    letterSpacing: '.375rem',
    whiteSpace: 'nowrap',
    fontSize: '1.75rem',
    _before: {
      content: `"/"`,
      marginLeft: '1.5rem',
      marginRight: '1.5rem',
    },
    color: 'white',
  },
}

const Text = {
  defaultProps,
  baseStyle,
  sizes,
  variants,
}

export { Text }
