import buildRoutes from '../shared/buildRoutes'

const staticRoutes = buildRoutes('pages', {
  // Sample routes

  /* Simplest case using a single placeholder */
  // single: routeFromUrl<{ id: number }>('single/[id]'),

  /* Route with multiple placeholder */
  // multiple: routeFromUrl<{ id: number; size: string }>('multiple/[id]/[size]'),

  /* For more complex requirements, you can specify your own function. The
   * parameter is always optional. If it's not passed, the function should
   * return the route with placeholders. */
  // manualFunction: (item?: { id: number }) => (
  //   item ? `details/${item.id}` : 'details/[id]'
  // ),

  help: 'help',
  contactUs: 'contact-us',
  about: 'about-pmall',
  privacyPolicy: 'privacy-policy',
  returnPolicy: 'return-refund-policy',
  termsOfService: 'terms-of-service',
  subscriptions: 'accounts/subscriptions',
})

export default staticRoutes
