import { createIcon } from '@chakra-ui/icon'
import React from 'react'

export const PmallBlackIcon = createIcon({
  displayName: 'PmallBlackIcon',
  viewBox: '0 0 124 20',
  path: (
    <svg width="124" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 0H9.46907C14.9034 0 18.3069 2.37117 18.3069 7.42883C18.3069 12.4865 14.9034 14.9135 9.46907 14.9135H5.43345V20H0V0ZM12.8698 7.42883C12.8698 5.51441 11.7543 4.8 9.46637 4.8H5.43345V10.1153H9.46907C11.7543 10.1144 12.8698 9.22793 12.8698 7.42883Z"
        fill="black"
      />
      <path
        d="M42.668 11.2613L38.8677 20H35.7754L31.9724 11.2613V20H26.5381V0H31.4855L37.3202 11.8856L43.155 0H48.1023V20H42.668V11.2613Z"
        fill="black"
      />
      <path
        d="M70.408 16.3964H62.8003L61.5377 20H55.7363L63.5776 0H69.6685L77.5053 20H71.694L70.408 16.3964ZM64.3162 12.0243H68.8641L66.6041 5.57117L64.3162 12.0243Z"
        fill="black"
      />
      <path d="M85.1362 0H90.5697V15.1991H100.608V20H85.1362V0Z" fill="black" />
      <path d="M108.529 0H113.963V15.1991H124V20H108.529V0Z" fill="black" />
    </svg>
  ),
})
