export const CONFIG = {
  // put config
  AMPLITUDE_ID: (process.env.NEXT_PUBLIC_AMPLITUDE_ID as string) || false,
  API_BASEURL: process.env.NEXT_PUBLIC_API_BASEURL as string,
  ADMIN_BASEURL: process.env.NEXT_PUBLIC_ADMIN_BASEURL as string,
  COOKIE_DOMAIN: process.env.NEXT_PUBLIC_COOKIE_DOMAIN as string,
  TAG_MANAGER_ID: process.env.NEXT_PUBLIC_TAG_MANAGER_ID || false,
  SHOPIFY_APP_BASEURL: process.env.NEXT_PUBLIC_SHOPIFY_APP_BASEURL as string,
  TRANSLATION_SERVICE_BASEURL: process.env
    .TRANSLATION_SERVICE_BASEURL as string,
  REPORT_MISSING_TRANSLATIONS:
    (process.env.REPORT_MISSING_TRANSLATIONS as string) === '1',
  SEGMENT_ID: (process.env.NEXT_PUBLIC_SEGMENT_ID as string) || false,
  SENTRY_DSN: (process.env.NEXT_PUBLIC_SENTRY_DSN as string) || '',
  STRIPE_KEY: process.env.NEXT_PUBLIC_STRIPE_KEY as string,
  STOREFRONT_API_BASEURL: process.env
    .NEXT_PUBLIC_STOREFRONT_API_BASEURL as string,
  OPTIMIZELY_ID: process.env.NEXT_PUBLIC_OPTIMIZELY_ID as string,
  BRAND_PUBLIC_KEY: (process.env.NEXT_PUBLIC_BRAND_PUBLIC_KEY as string) || '',
  BRAND_POST_PURCHASE_TYPEFORMID:
    (process.env.NEXT_PUBLIC_BRAND_POST_PURCHASE_TYPEFORMID as string) || '',
  GOVX_URL: (process.env.NEXT_PUBLIC_GOVX_URL as string) || '',
  STAMPED_STORE_URL: process.env.NEXT_PUBLIC_STAMPED_STORE_URL as string,
  STAMPED_API_KEY: process.env.NEXT_PUBLIC_STAMPED_API_KEY as string,
  BRAND_NAME: process.env.NEXT_PUBLIC_BRAND_NAME as string,
  APP_ENVIRONMENT: process.env.NODE_ENV as string,
  BRAND_CONTACT_EMAIL: process.env.NEXT_PUBLIC_BRAND_CONTACT_EMAIL as string,
  BRAND_STORE_URL: (process.env.NEXT_PUBLIC_BRAND_STORE_URL as string) || false,
  ENABLE_ACCOUNT_SYSTEM_WARRANTY:
    (process.env.NEXT_PUBLIC_ENABLE_ACCOUNT_SYSTEM_WARRANTY as string) ||
    'disabled',
  ENABLE_ACCOUNT_SYSTEM_GIFT_CARD:
    (process.env.NEXT_PUBLIC_ENABLE_ACCOUNT_SYSTEM_GIFT_CARD as string) ||
    'disabled',
  ENABLE_ACCOUNT_SYSTEM_SUBSCRIPTION:
    (process.env.NEXT_PUBLIC_ENABLE_ACCOUNT_SYSTEM_SUBSCRIPTION as string) ||
    'enabled',
  REFERRAL_ACCOUNT_SYSTEM_SUBSCRIPTION:
    (process.env.NEXT_PUBLIC_REFERRAL_ACCOUNT_SYSTEM_SUBSCRIPTION as string) ||
    'disabled',
  ENVIRONMENT: (process.env.NEXT_PUBLIC_VERCEL_ENV || 'development') as string,
  DATADOG_LOG_LEVEL:
    (process.env.NEXT_PUBLIC_DATADOG_LOG_LEVEL as string) || 'disabled',
  DATADOG_CLIENT_TOKEN:
    (process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN as string) || false,
  // The presence of this key is needed to allow backwards compatibility of the
  // checkout package
  ALLOW_PARTIAL_CONFIG: true,
  FREE_SHIPPING_THRESHOLD:
    (process.env.NEXT_PUBLIC_FREE_SHIPPING_THRESHOLD as string) || false,
  AFFIRM_PUBLIC_KEY:
    (process.env.NEXT_PUBLIC_AFFIRM_PUBLIC_KEY as string) || false,
  AFFIRM_SCRIPT_URL:
    (process.env.NEXT_PUBLIC_AFFIRM_SCRIPT_URL as string) || false,
  OSANO_SCRIPT_URL:
    (process.env.NEXT_PUBLIC_OSANO_SCRIPT_URL as string) || false,
  KLARNA_CLIENT_ID: (process.env.NEXT_PUBLIC_KLARNA_CLIENT_ID as string) || '',
  KLARNA_SCRIPT_URL:
    (process.env.NEXT_PUBLIC_KLARNA_SCRIPT_URL as string) || false,
  APP_URL: (process.env.NEXT_PUBLIC_APP_URL as string) || '',
  PMALL_BRAND: (process.env.NEXT_PUBLIC_PMALL_BRAND as string) || 'PMall',
  KUSTOMERAPP_CONTACT_URL:
    (process.env.NEXT_PUBLIC_KUSTOMERAPP_CONTACT_URL as string) || '',
  AUTH0_CLIENT_ID: (process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID as string) || '',
  AUTH0_DOMAIN: (process.env.NEXT_PUBLIC_AUTH0_DOMAIN as string) || '',
  AUTH0_AUDIENCE: (process.env.NEXT_PUBLIC_AUTH0_AUDIENCE as string) || '',
  BRAND_BLOG_URL: (process.env.NEXT_PUBLIC_BRAND_BLOG_URL as string) || '',
  GEOIP_ALLOW_SSR: process.env.NEXT_PUBLIC_GEOIP_ALLOW_SSR == 'true',
} as const

export type SETTINGS = typeof CONFIG
