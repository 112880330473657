import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete'
import React, { useCallback } from 'react'
import { FieldMeta, FieldDescription, Field, Form } from 'tinacms'

import { CMSLocalizedTextValue } from '../types'

import { selectBrand } from 'modules/brand/selectors'
import { useTypedSelector } from 'redux/store'

interface ExpectedFieldProps {
  /**
   * The variables that will be available to the translation. The key should be
   * the name of the variable / property. The value, the label that we'll
   * display in the field.
   */
  availableVariables?: Record<string, string>
  label: string
  name: string
}

interface TextFieldProps {
  field: Record<string, unknown> & ExpectedFieldProps
  input: Record<string, unknown> & { value: string | CMSLocalizedTextValue }
  form: Form
  meta: { dirty: boolean; valid: boolean; error: string | undefined }
}

function getKey(brand: string, text: string): string {
  let key = text
    .trim() // trim whitespace
    .toLowerCase() // lowerCase
    .replaceAll(/\{\{([a-z.\-_]+)\}\}/gi, 'var-$1') // {{key}} -> var-key
    .replaceAll(/[^a-z]+/gi, '-') // remove punctuation
  key = `cms:${brand}.${key}`

  return key
}

const useAutocompleteProvider = (suggestions: Record<string, string>) => {
  const stableSuggestions = JSON.stringify(suggestions)

  return useCallback(
    (query: string) =>
      Object.entries(suggestions)
        .filter(([key, label]) => key.includes(query) || label.includes(query))
        .map(([key, label]) => ({ key, label })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stableSuggestions]
  )
}

const TextField: React.FC<TextFieldProps> = ({ field, input, form, meta }) => {
  const brand = useTypedSelector(selectBrand())

  const onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value
    const key = getKey(brand || 'global', value)

    form.change(field.name, { key, value })
  }

  const dataProvider = useAutocompleteProvider(field.availableVariables || {})

  return (
    <>
      <FieldMeta
        error={meta.dirty && !meta.valid ? meta.error : ''}
        name={field.label}
      >
        <ReactTextareaAutocomplete
          minChar={0}
          movePopupAsYouType
          autoComplete="off"
          rows={1}
          style={{
            width: '100%',
            padding: 'var(--tina-padding-small)',
            borderRadius: 'var(--tina-radius-small)',
            fontSize: 'var(--tina-font-size-2)',
            background: 'var(--tina-color-grey-0)',
            color: 'var(--tina-color-grey-10)',
            border: '1px solid var(--tina-color-grey-2)',
            marginTop: '-2px',
            resize: 'none',
          }}
          listStyle={{
            width: '100%',
            fontSize: 'var(--tina-font-size-2)',
            background: 'var(--tina-color-grey-0)',
            listStyle: 'none',
            color: 'var(--tina-color-grey-10)',
          }}
          itemStyle={{
            width: '100%',
            backgroud: 'var(--tina-color-grey-0)',
          }}
          trigger={{
            '{': {
              afterWhitespace: true,
              output: ({ key }) => `{{${key}}}`,
              component: SelectionItem,
              dataProvider,
            },
          }}
          loadingComponent={() => <span>Loading...</span>}
          {...input}
          value={
            typeof input.value === 'string' ? input.value : input.value.value
          }
          onChange={onChange}
        />
      </FieldMeta>
      {field.availableVariables && (
        <FieldDescription>
          Type &#x7B; to see available variables.
        </FieldDescription>
      )}
    </>
  )
}

const SelectionItem: React.FC<any> = ({ entity, selected }) => {
  return (
    <p
      style={{
        background: selected ? 'var(--tina-color-primary)' : 'transparent',
        color: selected ? 'var(--tina-color-grey-0)' : 'inherit',
        padding: 'var(--tina-padding-small)',
        width: '100%',
      }}
    >
      {entity.label}
    </p>
  )
}

export const TextFieldPlugin: Field = {
  component: TextField,
  name: 'localized-text',
}

export default TextField
