import { FEATURE_FLAG_SETTINGS } from './featureFlags'

import { Config } from 'core/config'
class FeatureFlag<T extends { [key: string]: boolean }> extends Config<T> {
  updateValues(values: Partial<{ [k in keyof T]: T[k] | string }>) {
    Object.entries(values).forEach(([key, value]) => {
      if (typeof value === 'undefined') {
        return
      }
      if (typeof value === 'string') {
        this.configValues[key as keyof T] = (value === '1') as T[typeof key]
      } else {
        this.configValues[key as keyof T] = value as T[typeof key]
      }
    })
    this.validate()
  }
}
const flagManager = new FeatureFlag(FEATURE_FLAG_SETTINGS)
export const updateFeatureFlags = flagManager.updateValues.bind(flagManager)
export const exportFeatureFlags = flagManager.exportValues.bind(flagManager)

const getFeatureFlag = flagManager.get.bind(flagManager)
export default getFeatureFlag
