import {
  AccountActionTypes,
  ACCOUNT_LOAD_AUTH,
  LOGIN_STATUS_CHECKED,
} from './actionTypes'

export type AccountReducerState = {
  authLoaded: boolean
  auth: {
    token: string
    userId: string
  } | null
}

const initialState: AccountReducerState = {
  authLoaded: false,
  auth: null,
}

export function accountReducer(
  state = initialState,
  action: AccountActionTypes
): AccountReducerState {
  switch (action.type) {
    case ACCOUNT_LOAD_AUTH:
      return {
        ...state,
        authLoaded: true,
        auth: action.payload,
      }

    case LOGIN_STATUS_CHECKED:
      return {
        ...state,
        authLoaded: true,
      }

    default:
      return state
  }
}
