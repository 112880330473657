import fetch from 'isomorphic-unfetch'

import config from '../../core/config'
import { SettingsRecord } from './apiTypes'

import { SETTINGS } from 'core/config/config'
import { FeatureFlagSettingsType } from 'core/featureFlags/featureFlags'

export const TENANT_KEYS = {
  glamnetic: 'glamnetic:glamnetic',
  zitsticka: 'zitsticka:zitsticka',
  meridian: 'pangaea:meridiangrooming',
}

interface ShippingCountry {
  id: string
}

export function getBrandTenantKey(brand: string): string {
  if (typeof TENANT_KEYS[brand] === 'undefined') {
    throw new Error(`Cannot find key for brand ${brand}`)
  }

  return TENANT_KEYS[brand]
}

export const apiFetch = (
  path: string,
  opts?: RequestInit,
  baseUrl?: string
): Promise<Response> => {
  const BASE_URL = baseUrl || config('API_BASEURL')
  return fetch(`${BASE_URL}${path}`, opts)
}

export const apiFetchJson = async (
  path: string,
  opts?: RequestInit,
  baseUrl?: string
) => {
  const headers = opts?.headers || {}
  if (!headers['content-type']) {
    headers['content-type'] = 'application/json'
  }

  if (!opts) {
    opts = {}
  }

  const req = await apiFetch(path, { ...opts, headers }, baseUrl)
  return await req.json()
}

export const brandBasedAPIFetch = async (
  brand: string,
  path: string,
  opts?: RequestInit
): Promise<Response> => {
  const defaultHeaders = {
    'content-type': 'application/json',
    tenant: `${getBrandTenantKey(brand)}`,
  }

  opts = opts || {}
  opts.headers = opts.headers
    ? { ...defaultHeaders, ...opts.headers }
    : defaultHeaders

  return await apiFetchJson(`${path}`, opts)
}

export async function getBrandPublicSettings<
  K extends keyof typeof TENANT_KEYS
>(
  brand: K
): Promise<{
  configSettings: Partial<SETTINGS>
  featureFlags: Partial<FeatureFlagSettingsType>
}> {
  try {
    const res = (await brandBasedAPIFetch(
      brand,
      `/api/landlord/settings/public-settings`
    )) as unknown as SettingsRecord[]

    const result: {
      configSettings: Partial<SETTINGS>
      featureFlags: Partial<FeatureFlagSettingsType>
    } = res.reduce(
      (acc, rec) => {
        if (rec.title === 'Feature Flag') {
          acc.featureFlags[rec.key] = `${rec.value}`
        } else {
          acc.configSettings[rec.key] = `${rec.value}`
        }

        return acc
      },
      { configSettings: {}, featureFlags: {} }
    )
    return result
  } catch (err) {
    throw new Error(`Cannot find settings for brand ${brand}`)
  }
}

export function isValidBrand(brand: string): brand is keyof typeof TENANT_KEYS {
  return TENANT_KEYS[brand] !== undefined
}
