import { TraitValue, UserState } from '@pangaea-holdings/analytics-user-state'

import config from '../../../core/config'
import { trackUserTraits } from '../events'

class BrandSpecificUserState extends UserState {
  cookieDomain = config('COOKIE_DOMAIN')

  constructor() {
    super()

    if (typeof window !== 'undefined') {
      const win = window as Window & {
        userState?: BrandSpecificUserState
      }
      win.userState = this
    }
  }

  // notify other analytics tools about user traits changing
  onUserTraitsChange(traits: Record<string, TraitValue>) {
    console.log('userState - tracking user traits', traits)
    trackUserTraits(traits)
  }
}

export const userState = new BrandSpecificUserState()
