export const ACCOUNT_LOAD_AUTH = 'ACCOUNT_LOAD_AUTH'
export const LOGIN_STATUS_CHECKED = 'LOGIN_STATUS_CHECKED'

export interface AccountLoadAuth {
  type: typeof ACCOUNT_LOAD_AUTH
  payload: {
    token: string
    userId: string
  } | null
}

export interface LoginStatusChecked {
  type: typeof LOGIN_STATUS_CHECKED
}

export type AccountActionTypes = AccountLoadAuth | LoginStatusChecked
