import React from 'react'

const icons = {
  'question-outline': {
    path: (
      <g stroke="currentColor" strokeWidth="1.5">
        <path
          strokeLinecap="full"
          fill="none"
          d="M9,9a3,3,0,1,1,4,2.829,1.5,1.5,0,0,0-1,1.415V14.25"
        />
        <path
          fill="none"
          strokeLinecap="full"
          d="M12,17.25a.375.375,0,1,0,.375.375A.375.375,0,0,0,12,17.25h0"
        />
        <circle fill="none" strokeMiterlimit="10" cx="12" cy="12" r="11.25" />
      </g>
    ),
  },
  'chevron-right': {
    viewBox: '0 0 256 512',
    path: (
      <path
        fill="currentColor"
        d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"
      />
    ),
  },
  'chevron-left': {
    viewBox: '0 0 256 512',
    path: (
      <path
        fill="currentColor"
        d="M231.293 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L70.393 256 251.092 74.87c4.686-4.686 4.686-12.284 0-16.971L231.293 38.1c-4.686-4.686-12.284-4.686-16.971 0L4.908 247.515c-4.686 4.686-4.686 12.284 0 16.971L214.322 473.9c4.687 4.686 12.285 4.686 16.971-.001z"
      />
    ),
  },
  'shopping-cart': {
    viewBox: '0 0 576 512',
    path: (
      <path
        fill="currentColor"
        d="M551.991 64H129.28l-8.329-44.423C118.822 8.226 108.911 0 97.362 0H12C5.373 0 0 5.373 0 12v8c0 6.627 5.373 12 12 12h78.72l69.927 372.946C150.305 416.314 144 431.42 144 448c0 35.346 28.654 64 64 64s64-28.654 64-64a63.681 63.681 0 0 0-8.583-32h145.167a63.681 63.681 0 0 0-8.583 32c0 35.346 28.654 64 64 64 35.346 0 64-28.654 64-64 0-17.993-7.435-34.24-19.388-45.868C506.022 391.891 496.76 384 485.328 384H189.28l-12-64h331.381c11.368 0 21.177-7.976 23.496-19.105l43.331-208C578.592 77.991 567.215 64 551.991 64zM240 448c0 17.645-14.355 32-32 32s-32-14.355-32-32 14.355-32 32-32 32 14.355 32 32zm224 32c-17.645 0-32-14.355-32-32s14.355-32 32-32 32 14.355 32 32-14.355 32-32 32zm38.156-192H171.28l-36-192h406.876l-40 192z"
      />
    ),
  },
  warning: {
    viewBox: '0 0 512 512',
    path: (
      <g>
        <path
          fill="currentColor"
          d="M256 8C119 8 8 119.08 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 376a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm38.24-238.41l-12.8 128A16 16 0 0 1 265.52 288h-19a16 16 0 0 1-15.92-14.41l-12.8-128A16 16 0 0 1 233.68 128h44.64a16 16 0 0 1 15.92 17.59z"
        ></path>
        <path
          fill="transparent"
          d="M278.32 128h-44.64a16 16 0 0 0-15.92 17.59l12.8 128A16 16 0 0 0 246.48 288h19a16 16 0 0 0 15.92-14.41l12.8-128A16 16 0 0 0 278.32 128zM256 320a32 32 0 1 0 32 32 32 32 0 0 0-32-32z"
        ></path>
      </g>
    ),
  },
  check: {
    viewBox: '0 0 512 512',
    path: (
      <path
        fill="currentColor"
        d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z"
      />
    ),
  },
  'chevron-down': {
    viewBox: '0 0 448 512',
    path: (
      <path
        fill="currentColor"
        d="M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z"
      />
    ),
  },
  'chevron-up': {
    viewBox: '0 0 448 512',
    path: (
      <path
        fill="currentColor"
        d="M6.101 359.293L25.9 379.092c4.686 4.686 12.284 4.686 16.971 0L224 198.393l181.13 180.698c4.686 4.686 12.284 4.686 16.971 0l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L232.485 132.908c-4.686-4.686-12.284-4.686-16.971 0L6.101 342.322c-4.687 4.687-4.687 12.285 0 16.971z"
      />
    ),
  },
  'social-twitter': {
    viewBox: '0 0 32 32',
    path: (
      <path
        fill="currentColor"
        d="M32 6.076c-1.177 0.523-2.443 0.875-3.771 1.033 1.356-0.812 2.397-2.099 2.887-3.632-1.268 0.752-2.673 1.299-4.169 1.593-1.196-1.276-2.904-2.073-4.792-2.073-4.239 0-7.353 3.955-6.396 8.060-5.455-0.273-10.292-2.887-13.531-6.859-1.72 2.951-0.892 6.811 2.031 8.765-1.075-0.035-2.088-0.329-2.972-0.821-0.072 3.041 2.108 5.887 5.265 6.52-0.924 0.251-1.936 0.309-2.965 0.112 0.835 2.608 3.259 4.505 6.133 4.559-2.76 2.164-6.237 3.131-9.72 2.72 2.905 1.863 6.357 2.949 10.064 2.949 12.189 0 19.076-10.295 18.66-19.528 1.283-0.927 2.396-2.083 3.276-3.399z"
      />
    ),
  },
  'social-facebook': {
    viewBox: '0 0 32 32',
    path: (
      <path
        fill="currentColor"
        d="M30.233 0h-28.467c-0.976 0-1.767 0.791-1.767 1.767v28.468c0 0.975 0.791 1.765 1.767 1.765h15.327v-12.392h-4.171v-4.829h4.171v-3.561c0-4.133 2.524-6.384 6.212-6.384 1.767 0 3.284 0.132 3.727 0.191v4.32l-2.557 0.001c-2.005 0-2.393 0.953-2.393 2.351v3.084h4.783l-0.623 4.829h-4.16v12.391h8.155c0.973 0 1.764-0.791 1.764-1.767v-28.467c0-0.976-0.791-1.767-1.767-1.767z"
      />
    ),
  },
  'social-instagram': {
    viewBox: '0 0 32 32',
    path: (
      <path
        fill="currentColor"
        d="M16 2.884c4.272 0 4.779 0.016 6.467 0.093 4.336 0.197 6.361 2.255 6.559 6.559 0.077 1.687 0.092 2.193 0.092 6.465 0 4.273-0.016 4.779-0.092 6.465-0.199 4.3-2.219 6.361-6.559 6.559-1.688 0.077-2.192 0.093-6.467 0.093-4.272 0-4.779-0.016-6.465-0.093-4.347-0.199-6.361-2.265-6.559-6.56-0.077-1.687-0.093-2.192-0.093-6.465 0-4.272 0.017-4.777 0.093-6.465 0.199-4.303 2.219-6.361 6.559-6.559 1.688-0.076 2.193-0.092 6.465-0.092zM16 0c-4.345 0-4.889 0.019-6.596 0.096-5.811 0.267-9.040 3.491-9.307 9.307-0.079 1.708-0.097 2.252-0.097 6.597s0.019 4.891 0.096 6.597c0.267 5.811 3.491 9.040 9.307 9.307 1.708 0.077 2.252 0.096 6.597 0.096s4.891-0.019 6.597-0.096c5.805-0.267 9.043-3.491 9.305-9.307 0.079-1.707 0.097-2.252 0.097-6.597s-0.019-4.889-0.096-6.596c-0.261-5.805-3.489-9.040-9.305-9.307-1.708-0.079-2.253-0.097-6.599-0.097zM16 7.784c-4.537 0-8.216 3.679-8.216 8.216s3.679 8.217 8.216 8.217 8.216-3.679 8.216-8.217c0-4.537-3.679-8.216-8.216-8.216zM16 21.333c-2.945 0-5.333-2.387-5.333-5.333 0-2.945 2.388-5.333 5.333-5.333s5.333 2.388 5.333 5.333c0 2.947-2.388 5.333-5.333 5.333zM24.541 5.54c-1.061 0-1.921 0.86-1.921 1.92s0.86 1.92 1.921 1.92c1.060 0 1.919-0.86 1.919-1.92s-0.859-1.92-1.919-1.92z"
      />
    ),
  },
  times: {
    viewBox: '0 0 320 512',
    path: (
      <path
        fill="currentColor"
        d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
      />
    ),
  },
  plus: {
    viewBox: '0 0 384 512',
    path: (
      <path
        fill="currentColor"
        d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"
      />
    ),
  },
  minus: {
    viewBox: '0 0 384 512',
    path: (
      <path
        fill="currentColor"
        d="M376 232H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h368c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"
      />
    ),
  },
}
export default icons
