import { layerPush } from '../analytics/functions/track'

enum EVENTS {
  EXPERIMENT_ACTIVATED = 'experiment_activated',
  EXPERIMENT_MANUAL_ACTIVATED = 'experiment_manual_activated',
}

export const trackExperimentActivated = (experimentName: string) => {
  layerPush({
    event: EVENTS.EXPERIMENT_ACTIVATED,
    experiment_api_name: experimentName,
  })
}

/**
 * This event is temporary
 */
const trackExperimentManualActivated = (name: string, variant: string) => {
  layerPush({
    event: EVENTS.EXPERIMENT_MANUAL_ACTIVATED,
    experiment_name: name,
    variant_name: variant,
  })
}

function handleActivation(experimentName: string, variantName: string) {
  console.log('handleActivation', { experimentName, variantName })
  trackExperimentManualActivated(experimentName, variantName)
}

/**
 * This is meant as a temporary measure for debugging discrepancy between
 * optimizely and amplitude reports.
 *
 * Each optimizely experiment should call:
 *
 * ```
 * window.optimizelyExperimentActivated = window.optimizelyExperimentActivated || []
 * window.optimizelyExperimentActivated.push([experiment_name, experiment_variant])
 * ```
 */
if (process.browser) {
  const currentExperiments: [string, string][] | undefined =
    window['optimizelyExperimentActivated']

  if (Array.isArray(currentExperiments)) {
    currentExperiments.forEach(([experiment, variant]) =>
      handleActivation(experiment, variant)
    )
  }

  window['optimizelyExperimentActivated'] = {
    push: ([name, variant]: [string, string]) => {
      handleActivation(name, variant)
    }
  }
}
