import {
  BrandServicesAddress,
  CURRENCY_COOKIE,
  SHIPPING_COUNTRY_COOKIE,
  isSupportedCurrency,
} from '@pangaea-holdings/pangaea-checkout'
import { GetServerSidePropsContext } from 'next'
import cookies from 'next-cookies'

import { CURRENCIES } from 'modules/brand/currencies'

export const getAddressInfo = (address: BrandServicesAddress) => {
  const {
    first_name,
    last_name,
    street_1,
    street_2,
    city,
    province,
    zip,
    country,
  } = address

  const fullName = `${first_name}${last_name ? ` ${last_name}` : ''}`
  const fullStreet = `${street_1}${street_2 ? ` ${street_2}` : ''}`
  const cityStr = `${city ? `${city}, ` : ''}`
  const provinceStr = `${province ? `${province}, ` : ''}`
  const cityProvinceZip = `${cityStr}${provinceStr}${zip}`

  return [fullName, fullStreet, cityProvinceZip, country]
}

/**
 * Looks for the user's selected currency in the following order:
 * `currency` query parameter, `selected_currency` cookie, 'USD'
 * if `country` is passed, it will try to get the default currency for that
 * country.
 */
export const getServerSideCurrency = (
  ctx: Parameters<typeof cookies>[0] & { query: GetServerSidePropsContext['query']},
  country?: string
): string => {
  let selectedCurrency =
    (ctx.query.currency as string) || cookies(ctx)[CURRENCY_COOKIE]

  if (!selectedCurrency && country) {
    selectedCurrency = CURRENCIES[country]?.currency_code
  }

  const currency = isSupportedCurrency(selectedCurrency || '')
    ? (selectedCurrency as string)
    : 'USD'

  return currency.toUpperCase()
}

/**
 * Looks for the user's country based off the request in the following order:
 * `selected_shipping_country` cookie.
 * `CloudFront-Viewer-Country` header (set by cloudfront).
 * GeoIp
 * `undefined`
 *
 * We may need to find a better place for this.
 */
export const getServerSideCountry = (
  ctx: Parameters<typeof cookies>[0],
  skipCookie: boolean = false
): string | undefined => {
  const country =
    (!skipCookie && cookies(ctx)[SHIPPING_COUNTRY_COOKIE]) ||
    // @see https://aws.amazon.com/about-aws/whats-new/2020/07/cloudfront-geolocation-headers/
    (ctx.req?.headers['cloudfront-viewer-country'] as string | undefined) ||
    // @see https://vercel.com/docs/concepts/edge-network/headers#x-vercel-ip-country
    (ctx.req?.headers['x-vercel-ip-country'] as string | undefined)

  if (country && country.match(/^[A-Z]{2}$/i)) {
    return country
  }

  return undefined
}
