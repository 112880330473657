import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useCMS } from 'tinacms'

import { CMSLocalizedTextValue } from '../types'

/**
 * This hook is needed because phrases keys are generated dynamically by the
 * CMS and live preview would mean sending a lot of incomplete phrases for
 * translation.
 */
const useCMSTranslation = (): ((
  cmsValue: CMSLocalizedTextValue | string,
  data?: Record<string, string | number>
) => string) => {
  const { t } = useTranslation()
  const cms = useCMS()
  const isSidebarOpen = cms.sidebar?.isOpen || false

  const tFunc = useCallback(
    (cmsValue, data = {}) => {
      if (typeof cmsValue === 'string') {
        console.warn(
          `CMS Translation got string parameter "${cmsValue}" please update it using the CMS.`
        )
        return cmsValue
      }

      const { key, value } = cmsValue

      // Use a dummy key when the sidebar is open so we don't spam the translation
      // service with incomplete phrases.
      const k = isSidebarOpen ? 'test:test' : key

      return t(k, value, data)
    },
    [isSidebarOpen, t]
  )

  return tFunc
}

export default useCMSTranslation
