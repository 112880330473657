import { createContext, FC, useState } from 'react'

import { JsonObject } from 'utils/types'
interface GlobalCMSContextType {
  globalCMSValues: any
  isLoggedToCMS: boolean
  setIsLoggedToCMS: (isLoggedIn: boolean) => void
}

export const GlobalCMSContext = createContext<GlobalCMSContextType>({
  globalCMSValues: {},
  isLoggedToCMS: false,
  setIsLoggedToCMS: () => {
    // this is intentional
  },
})

const GlobalCMSContextProvider: FC<{ globalCMSValues: JsonObject }> = ({
  children,
  globalCMSValues = {},
}) => {
  const [isLoggedToCMS, setIsLoggedToCMS] = useState(false)

  return (
    <GlobalCMSContext.Provider
      value={{
        globalCMSValues,
        isLoggedToCMS,
        setIsLoggedToCMS,
      }}
    >
      {children}
    </GlobalCMSContext.Provider>
  )
}

export default GlobalCMSContextProvider
