import {
  createContext,
  PropsWithChildren,
  ReactElement,
  useState,
  useCallback,
} from 'react'

import { CMSPageBaseState, CMSContextValue } from '../types'

const getInitialState = (): CMSContextValue<{}> => ({
  state: {},
  set: () => {
    throw new Error('Context not initialized')
  },
  replaceState: () => {
    throw new Error('Context not initialized')
  },
})

function useStateMutator<T extends CMSPageBaseState>(initialValue: T) {
  const [state, setState] = useState(initialValue)

  const set = useCallback(<K extends keyof T>(key: K, val: T[K]) => {
    setState((prevState) => ({ ...prevState, [key]: val }))
  }, [])

  const replaceState = useCallback((newState: T) => {
    setState({ ...newState })
  }, [])

  return { state, set, replaceState }
}

export const CMSPageContext = createContext(getInitialState())

export const CMSPageContextProvider = <T extends CMSPageBaseState>({
  initialValue,
  children,
}: PropsWithChildren<{ initialValue: T }>): ReactElement => {
  const state = useStateMutator(initialValue)

  return (
    <CMSPageContext.Provider value={state}>{children}</CMSPageContext.Provider>
  )
}
