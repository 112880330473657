import * as Sentry from '@sentry/node'
import { useContext, useEffect } from 'react'
import { Block } from 'react-tinacms-inline'
import { useForm, FormOptions, useFormScreenPlugin } from 'tinacms'

import { selectBrand } from '../../brand/selectors'
import { saveCmsGlobalComponent } from '../api'
import { GlobalCMSContext } from './../context'
import { useCheckCMSStatus } from './useCMSLoggedStatus'

import { useTypedSelector } from 'redux/store'
import { JsonObject } from 'utils/types'

export interface BlockConfig {
  [key: string]: Block
}

export default function useGlobalCMS<T extends JsonObject>(
  globalComponentId: string,
  initialValues: T,
  opts: Omit<FormOptions<T>, 'id' | 'initialValues' | 'onSubmit'>
) {
  const brand = useTypedSelector(selectBrand())
  useCheckCMSStatus()
  const { globalCMSValues } = useContext(GlobalCMSContext)
  const fields = opts.fields ? [...opts.fields] : []
  const formOptions: FormOptions<T> = {
    ...opts,
    id: globalComponentId,
    initialValues:
      (initialValues?.globalComponentId as Partial<T>) ?? undefined,
    fields,
    onSubmit: submitPage(brand!, globalComponentId),
  }

  const [data, form] = useForm(formOptions)

  useFormScreenPlugin(form, null, 'popup')

  useEffect(() => {
    if (form && globalCMSValues && globalCMSValues[globalComponentId]) {
      form.updateInitialValues(globalCMSValues[globalComponentId])
    }
  }, [form, globalCMSValues])

  return { data, form }
}

function submitPage(brand: string, globalComponentId: string) {
  return async (formData: JsonObject) => {
    try {
      await saveCmsGlobalComponent(brand, globalComponentId, formData)
      return true
    } catch (err) {
      Sentry.captureException(err)
      alert('Error trying to save to Global CMS')
      return false
    }
  }
}
