import { CONFIG } from './config'

export type ConfigValue = string | number | boolean

export class Config<T extends { [key: string]: ConfigValue }> {
  configValues: T

  constructor(configValues?: T) {
    if (configValues) {
      this.configValues = configValues
    }
  }

  validate() {
    Object.keys(this.configValues).forEach((key) => {
      if (this.configValues[key] === undefined) {
        throw new Error(
          `Undefined config value "${key}", ensure it exists in src/core/config/config.ts`
        )
      }
    })
  }

  get<K extends keyof T>(key: K): T[K] {
    return this.configValues[key]
  }

  updateValues(values: Partial<T>) {
    Object.entries(values).forEach(([key, value]) => {
      if (typeof value === 'undefined') {
        return
      }
      this.configValues[key as keyof T] = value
    })
    this.validate()
  }

  exportValues(): T {
    return { ...this.configValues }
  }
}

const configObj = new Config(CONFIG)
const config = configObj.get.bind(configObj)

export const updateConfig = configObj.updateValues.bind(configObj)
export const exportConfig = configObj.exportValues.bind(configObj)

export default config
