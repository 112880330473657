import { createIcon } from '@chakra-ui/icon'
import React from 'react'

export const OutlineCloseIcon = createIcon({
  displayName: 'OutlineCloseIcon',
  viewBox: '0 0 12 12',
  path: (
    <>
      <path
        d="M2.1135 12L0 9.885L3.885 6L0 2.115L2.115 0L6 3.885L9.885 0L12 2.115L8.115 6L12 9.885L9.8865 12L6 8.115L2.1135 12Z"
        fill="currentColor"
      />
    </>
  ),
})
