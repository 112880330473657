import Head from 'next/head'
import React from 'react'

import config from '../../../core/config'

const OptimizelyScriptTag = () => {
  const OPTIMIZELY_ID = config('OPTIMIZELY_ID')

  if (!OPTIMIZELY_ID) {
    return null
  }

  return (
    <Head key="optimizely_script_tag">
      <script>  window.optimizelyFeatureFlags = window.optimizelyFeatureFlags || [] </script>
      <script
        type="text/javascript"
        src={`https://cdn.optimizely.com/js/${OPTIMIZELY_ID}.js`}
      ></script>
    </Head>
  )
}

export default OptimizelyScriptTag
