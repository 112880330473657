import {
  Heading as ChakraHeading,
  HeadingProps as ChakraHeadingProps,
} from '@chakra-ui/core'
import React from 'react'

type HeadingOptions = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
type props =
  | { variant: HeadingOptions; as?: never }
  | { variant: string; as: any }

const headingsArr = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']

const Heading: React.FC<ChakraHeadingProps & props> = ({
  as,
  variant,
  ...props
}) => {
  const headingVariant = headingsArr.find((item) => item === variant)
  const headingType = headingVariant && !as ? variant : as
  return (
    <ChakraHeading
      as={headingType}
      variant={variant}
      {...props}
    />
  )
}

export default Heading