import { useContext } from 'react'
import { InlineForm } from 'react-tinacms-inline'
import { FormOptions } from 'tinacms'

import { CMSPageContextProvider } from '../context/Provider'
import useGlobalCMS from '../hooks/useGlobalCMS'
import { CMSPageBaseState } from '../types'
import { GlobalCMSContext } from './../context'

import { JsonObject } from 'utils/types'

export function WithGlobalCMSOption<
  P,
  V extends JsonObject = JsonObject,
  S extends CMSPageBaseState = CMSPageBaseState
>(
  Component: React.FC<P>,
  globalComponentId: string,
  opts: Omit<FormOptions<V>, 'id' | 'initialValues' | 'onSubmit'>,
  initialState: S = {} as S
): React.FC<P> {
  type ComponentProps = P

  const GlobalComponent = (props: ComponentProps) => {
    const { globalCMSValues } = useContext(GlobalCMSContext)
    const { form, data } = useGlobalCMS(
      globalComponentId,
      globalCMSValues,
      opts
    )

    return (
      <CMSPageContextProvider<S> initialValue={initialState}>
        <InlineForm form={form}>
          <Component {...props} cmsValues={data as V} />
        </InlineForm>
      </CMSPageContextProvider>
    )
  }

  return GlobalComponent
}
