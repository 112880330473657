/**
 * Any value that can be serialized as JSON **except objects**.
 */
export type JsonPrimitive =
  | string
  | null
  | undefined
  | boolean
  | number
  | JsonPrimitive[]

/**
 * Any object that can be serializable as JSON.
 */
export interface JsonObject {
  [key: string]: JsonPrimitive | JsonObject | JsonObject[]
}

/**
 * Represents anything that can be serializable as JSON.
 */
export type JsonSerializable = JsonObject | JsonPrimitive

// @deprecated use Record<string, any> instead
export type AnyDict = { [k: string]: any }

// @deprecated use Record<string, string> instead
export type StringDict = { [k: string]: string }

export function isPromise<T extends any>(v: any | Promise<T>): v is Promise<T> {
  return typeof (v as Promise<T>).then === 'function'
}

/**
 * Useful as a default case in switch statements to ensure exhaustive
 * handling of enums.
 */
export function assertNever(x: never): never {
  throw new Error(`Unexpected value ${x}`)
}

/**
 * Like `assertNever` but it does not throw at runtime.
 *
 * Useful for reducers that should only handle a subset of possible actions but
 * that subset needs to be handled exhaustively.
 */
export function softAssertNever(_x: never): void {}

/**
 * Infers the type that the promise resolves to.
 */
export type InferPromise<T extends Promise<any>> = T extends Promise<infer R>
  ? R
  : unknown

/**
 * Alias for an async function
 */
export type AsyncFunction<A extends any[] = any[], R extends any = any> = (
  ...args: A
) => Promise<R>

/**
 * Returns the definition of a function with the same parameters and return
 * type of T.
 */
export type MirrorAsyncFunction<T extends AsyncFunction> = (
  ...args: Parameters<T>
) => Promise<InferPromise<ReturnType<T>>>
