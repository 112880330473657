import { logger } from '@pangaea-holdings/pangaea-checkout'
import { GetServerSidePropsContext } from 'next'

import { TENANT_KEYS, isValidBrand } from '../api/index'

/**
 * Extracts the requested brand from the url.
 */
export function findBrandByPath(
  path: string,
  logFailReason: boolean = false
): keyof typeof TENANT_KEYS | undefined {
  const logData = { action: 'findBrandByPath', path }
  const parts = path.split('/')

  if (parts.length <= 1) {
    if (logFailReason) {
      logger.warn('No parts length', {
        ...logData,
        logType: 'noPartsFromPath',
        parts,
      })
    }
    return undefined
  }

  const brand = parts[1]
  const isValid = isValidBrand(brand)

  if (logFailReason && !isValid) {
    logger.warn(`Got invalid brand=${brand} from url.`, {
      ...logData,
      logType: 'notValidFromUrl',
      brand,
      parts,
    })
  }

  return isValid ? brand : undefined
}

/**
 * Extracts the requested brand from the ServerSide Context.
 */
export function findBrandFromContext(
  ctx: GetServerSidePropsContext<{ brand?: string }>,
  logFailReason: boolean = false
): keyof typeof TENANT_KEYS | undefined {
  const { req, params } = ctx
  const { url } = req
  const logData = { action: 'findBrandFromContext', req }

  if (params?.brand) {
    const brand = params?.brand
    const isValid = isValidBrand(brand)

    if (logFailReason && !isValid) {
      logger.warn(`Got invalid brand=${params.brand} in parameters.`, {
        ...logData,
        logType: 'notValidFromParams',
        params,
      })
    }

    return isValid ? brand : undefined
  }

  if (!url) {
    if (logFailReason) {
      logger.warn(`Trying to get brand without url`, {
        ...logData,
        logType: 'noUrl',
      })
    }
    return undefined
  }

  return findBrandByPath(url, logFailReason)
}
