import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { useTypedSelector, useTypedDispatch } from '../../../redux/store'
import { setCurrentBrand } from '../actions'
import { findBrandByPath } from '../functions'
import { selectBrand } from '../selectors'

export function useBrandListener() {
  const { asPath } = useRouter()
  const currentBrand = useTypedSelector(selectBrand())
  const dispatch = useTypedDispatch()

  useEffect(() => {
    const brand = findBrandByPath(asPath)

    if (brand !== currentBrand) {
      dispatch(setCurrentBrand(brand))
    }
    // We intentionally left currentBrand out of the dependencies.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath])
}
