import { ThunkActionCreator } from '../../redux/rootReducer'
import { removeCookie, setCookie, getCookie } from '../../utils/cookie'
import { apiFetchJson, getBrandTenantKey } from '../api'
import {
  ACCOUNT_LOAD_AUTH,
  AccountLoadAuth,
  LOGIN_STATUS_CHECKED,
} from './actionTypes'

type CookieDict = {
  [key: string]: string
}

export const loadAuth: ThunkActionCreator<void> = (cookies: CookieDict) => (
  dispatch
) => {
  // TODO handle delete cookie
  const action: AccountLoadAuth = {
    type: ACCOUNT_LOAD_AUTH,
    payload: null,
  }

  if (cookies.userToken && cookies.userId) {
    action.payload = {
      token: cookies.userToken,
      userId: cookies.userId,
    }
  }

  dispatch(action)
}

export const submitLogin: ThunkActionCreator<Promise<any>> = (
  email: string,
  brand: string
) => async (dispatch) => {
  return apiFetchJson(`/api/account/magic-link`, {
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
      tenant: `${getBrandTenantKey(brand)}`,
    },
    body: `email=${encodeURIComponent(email)}`,
  })
}

export const signOut = () => {
  removeCookie('userToken')
  removeCookie('userId')
  window.location.reload()
}

export const loadAuthFromCookie: ThunkActionCreator<Promise<
  any
>> = () => async (dispatch) => {
  const token = getCookie('userToken')
  const userId = getCookie('userId')

  // TODO hit a /me endpoint
  if (token && userId) {
    dispatch({
      type: ACCOUNT_LOAD_AUTH,
      payload: {
        token,
        userId,
      },
    })
  } else {
    // not logged in
    dispatch({
      type: ACCOUNT_LOAD_AUTH,
      payload: null,
    })
  }
}

export const loginStatusChecked: ThunkActionCreator<Promise<any>> =
  () => async (dispatch) => {
    dispatch({
      type: LOGIN_STATUS_CHECKED,
    })
  }

if (process.browser) {
  window['fakeLogin'] = () => {
    setCookie(
      'userToken',
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjI5Yzk0OTZjYTFiYTcyY2FjYzVlNmIzYWI1MGIwOWI4MzA3YjFhZTIyOTllNjJjMGI4NjQ3MjY2NWM3NGQzYmZmMDUyMmRmMjM0YjIwZTk5In0.eyJhdWQiOiIyIiwianRpIjoiMjljOTQ5NmNhMWJhNzJjYWNjNWU2YjNhYjUwYjA5YjgzMDdiMWFlMjI5OWU2MmMwYjg2NDcyNjY1Yzc0ZDNiZmYwNTIyZGYyMzRiMjBlOTkiLCJpYXQiOjE1OTQwNzIxOTAsIm5iZiI6MTU5NDA3MjE5MCwiZXhwIjoxNjI1NjA4MTkwLCJzdWIiOiI4Njc0MDUiLCJzY29wZXMiOltdfQ.ZZydgZa9JoOSAYVte_U4agDOGPyCzYvf7WOyU4gSgkpLLwobnt0t6I3gOEf9zYArf_YjG-CafYsuVcanWOh9DEVflD2EXzXUVUQkObNUfsq-uyW3U4tkl7RF8FRd8Y2hxZ48ofKscHQcCPWWojCI6o-_LTweY0ZmGYC2Ym6dqy9gIyf16QEdAvagihoJJbOXuB8llcBrm80LypmnAViEMtCFqdBHFE5jxrbEFE1XB66QYPTvma7XSkD_J5PBK7_fBH3F0_tBq8UWgXExthFTqiRk71F38nkIeLSC3eeo56qQfNsy5xPOOxSbGrk29KaZ7poa_Pp7WrKNjHTUV5OvImmpzpw13s4BFebhLT8xfo0Fdh0dxXpVCNgIYMk39BI2pH24d-_DHubYfgfTP3dce3lgrmaWHaQ9lhVlbx1VAEoREJPNeQ6i1QdXXPkwLY1Ot_VSG5Ew9pwuSh1A1o4sM3571xsATwZRjk9bWG99lvcS9rj6YzZfRt65jh-NZ3-JNMzGchNF_GXm_N1vBd1esVMpCoi1Drm_d_ulcl9qv4HfapilBX9gtycUljkKTLWqmMq_qot-dHsJNRo3RkyLErDCZz9-aKEcRYiIA3WXVmZ2Qv69uThLo5pZAgXWOPnHtuBCpJaVr1ilo6OJYqIMPf8kEwBA4ZLBbDkzjJXuJsE'
    )
    setCookie('userId', '867405')
  }
}
