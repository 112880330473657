import { Box, BoxProps, Text } from '@chakra-ui/core'
import { CheckoutContext } from '@pangaea-holdings/pangaea-checkout'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'

import useCMSTranslation from '../../../cms/hooks/useCMSTranslation'

interface FreeShippingBannerInterface extends BoxProps {
  cmsValues?: {
    isEnabled: boolean
    bannerText: string
    bgColor: string
    textColor: string
  }
}

export const FreeShippingBanner: React.FC<FreeShippingBannerInterface> = ({
  cmsValues = {},
  ...otherBoxProps
}) => {
  const { isEnabled, bgColor, bannerText, textColor } = cmsValues

  const { t } = useTranslation()
  const defaultText = t(
    'common:free-shipping-message',
    "You've got FREE Shipping!"
  )
  const text = bannerText || defaultText
  const availableVariables = { defaultText }
  const cmsT = useCMSTranslation()
  const { state } = useContext(CheckoutContext)
  const { items } = state?.cart?.cart || {}
  return (
    <>
      {isEnabled && items && items.length > 0 && (
        <Box bg={bgColor} {...otherBoxProps}>
          <Text textAlign="center" color={textColor}>
            <ReactMarkdown>{cmsT(text, availableVariables)}</ReactMarkdown>
          </Text>
        </Box>
      )}
    </>
  )
}
