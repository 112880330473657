import { softAssertNever } from '../../utils/types'
import { BrandActionTypes, BrandActions } from './actionTypes'

export interface BrandReducerState {
  brand?: string
}

// Brand is set synchronously first thing in the app in order to avoid flashes.
const initialBrandState = {}

export function brandReducer(
  state = initialBrandState,
  action: BrandActionTypes
): BrandReducerState {
  switch (action.type) {
    case BrandActions.SET_CURRENT_BRAND:
      return {
        ...state,
        brand: action.payload,
      }

    default:
      softAssertNever(action.type)
      return state
  }
}
