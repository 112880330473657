import * as Sentry from '@sentry/node'

import { InferPromise, AsyncFunction, MirrorAsyncFunction } from './types'

export const calculateDiscountPercentage = (
  originalValue: number,
  newValue: number
): number => {
  return originalValue > newValue
    ? Math.floor(((originalValue - newValue) / originalValue) * 100)
    : 0
}

/**
 * Basically an async setTimeout
 */
export function wait(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(() => resolve(), ms))
}

interface RetryOptions {
  maxAttempts?: number
  retryDelay?: number
  logger?: {
    warn: (...args: any[]) => void
    error: (...args: any[]) => void
  }
}

/**
 * Returns a function that will call `func` and in case of failure, will call
 * it again at most `maxAttempts - 1` times.
 */
export function withRetry<T extends AsyncFunction>(
  func: T,
  { maxAttempts = 3, retryDelay = 100, logger = console }: RetryOptions = {}
): MirrorAsyncFunction<T> {
  return async (
    ...args: Parameters<T>
  ): Promise<InferPromise<ReturnType<T>>> => {
    let attempt = maxAttempts
    const logContext = {
      fuctionName: func.name,
      retryDelay,
      maxAttempts,
    }

    // If `maxAttempts` is 1 we skip the whole try/catch and call it normally.
    // but... why would you use this if you don't want to retry?
    while (--attempt >= 1) {
      try {
        return await func(...args)
      } catch (e) {
        const ctx = {
          ...logContext,
          attempt: maxAttempts - attempt,
          status: 'failed',
          action: 'retry',
          error: e instanceof Error ? serializeError(e) : e,
        }

        // Make sure the original error message makes it to the logs.
        logger.warn(e, ctx)
        logger.warn(
          `Function '${func.name}' failed.\nWill retry ${attempt} more times`,
          ctx
        )

        if (retryDelay) {
          await wait(retryDelay)
        }
      }
    }

    try {
      return await func(...args)
    } catch (e) {
      logger.warn(`Function '${func.name}' failed.\nNo retries left`, {
        ...logContext,
        attempt: maxAttempts,
        status: 'failed',
        action: 'give-up',
        error: e instanceof Error ? serializeError(e) : e,
      })
      Sentry.captureException(e)
      // Rethrow the error on the last try.
      throw e
    }
  }
}

/**
 * Returns a simple object from an Error instance.
 */
export function serializeError(error: Error) {
  // Some browsers add non-standard properties to errors which may be useful.
  const { message, name, stack, ...rest } = error

  return { message, name, stack, ...rest }
}

/**
 * Removes all blank or null values from the object or array
 */
export function cleanObject(obj: Array<any> | Object): Object | Array<any> {
  if (!obj) return []

  const newObj = Object.fromEntries(
    Object.entries(obj)
      .filter(([_, v]) => v !== null && v !== '')
      .map(([k, v]) => [k, v === Object(v) ? cleanObject(v) : v])
      .filter(([_, v]) =>
        v === Object(v) ? Object.keys(cleanObject(v)).length > 0 : v
      )
  )

  return Array.isArray(obj) ? Object.values(newObj) : newObj
}
