import space from '../../common/sizes'

const parts = {
  overlay: 'the modal overlay',
  content: 'the modal content wrapper',
  header: 'the modal content header',
  body: 'the modal main content area',
  footer: 'the modal footer action',
}

type Dict = Record<string, any>

const baseStyleOverlay = {
  background: 'modalBg',
  zIndex: 'modal',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  p: [2, 2, 0],
}

const baseStyleContent = {
  borderRadius: 'md',
  bg: 'white',
  color: 'inherit',
  maxH: 'calc(100vh - 7.5rem)',
  boxShadow: 'md',
  w: 'fit-content',
  my: [8, 8, '3.75rem'],
  mx: [5, 10],
  px: [0, 1, 3],
  py: [5, 10],
}

const baseStyleHeader = {
  px: 6,
  py: 4,
  fontSize: 'lg',
  fontWeight: 'bold',
}

const baseStyleBody = {
  px: 6,
  py: 2,
  flex: 1,
  overflow: 'auto',
}

const baseStyleFooter = {
  px: 6,
  py: 4,
}

const baseStyle = {
  overlay: baseStyleOverlay,
  content: baseStyleContent,
  header: baseStyleHeader,
  body: baseStyleBody,
  footer: baseStyleFooter,
}

const variants = {
  center: {
    content: {
      textAlign: 'center',
      bg: 'modalWindow',
      color: 'modalText',
    },
    body: {
      mx: 'auto',
    },
  },
  error: {
    content: {
      bg: 'darkColor',
      color: 'white',
    },
  },
}

/**
 * Since the `maxWidth` prop references theme.sizes internally,
 * we can leverage that to size our modals.
 */
function getSize(value: string) {
  if (value === 'full') {
    return { content: { maxW: '100vw', h: '100vh' } }
  }

  return {
    content: { maxW: space[value], flexGrow: 1 },
  }
}

const sizes = {
  xs: getSize('xs'),
  sm: getSize('sm'),
  md: getSize('md'),
  lg: getSize('lg'),
  xl: getSize('xl'),
  '2xl': getSize('2xl'),
  '3xl': getSize('3xl'),
  '4xl': getSize('4xl'),
  '5xl': getSize('5xl'),
  '6xl': getSize('6xl'),
  full: getSize('full'),
  errorModal: getSize('lg'),
}

const defaultProps = {}

const Modal = {
  variants,
  parts,
  baseStyle,
  sizes,
  defaultProps,
}

export default Modal
