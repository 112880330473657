import styled, { CreateStyled } from '@emotion/styled'
import { useTheme as _useTheme } from 'emotion-theming'

import { theme as zitstickaTheme } from '../design/themes/zitsticka-theme'
import { useSelectedTheme } from '../hooks/useSelectedTheme'
import { theme as glamneticTheme } from './themes/glamnetic-theme'

export const useTheme = (...args: Parameters<typeof _useTheme>) => {
  const selectedTheme = useSelectedTheme()
  return _useTheme<typeof selectedTheme>(...args)
}

export default styled as CreateStyled<
  typeof glamneticTheme | typeof zitstickaTheme
>
