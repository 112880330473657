import space from './../common/sizes'
import { components } from './components'
import icons from './icons'
import typography from './typography'

const sizes = {
  boxed: '1300px',

  heightXl: '3.563rem',
  heightXlTablet: '3.375rem',
  heightXlMobile: '3.125rem',

  heightLg: '3.438rem',
  heightLgTablet: '2.25rem',
  heightLgMobile: '1.938rem',

  heightMd: '2.13rem',
  heightMdTablet: '1.68rem',
  heightMdMobile: '1.68rem',

  heightSm: '2rem',
  heightSmTablet: '1.75rem',
  heightSmMobile: '1.5rem',

  starsWidth: '.75rem',
  starsHeight: '.75rem',
}

const colors = {
  transparent: 'transparent',
  current: 'currentColor',
  black: '#000',
  white: '#fff',
  disabled: '#CFD1C8',
  secondary: '#696969',
  footerPMall: '#09b5ea',
  pdpBg: '#ffbaa6',
  // brand specific
  topNavBg: '#fff',
  topNavBgMobile: '#fff',
  topNavText: '#7c7c7c',
  topNavTextMobile: '#414141',
  topNavTextHover: '#414141',
  topNavCartCount: '#FF0404',
  brandInfoBarBg: '#ffffff',
  brandInfoBarText: '#223345',
  promotionBg: 'hsla(0,0%,97%,.25)',
  promotionText: '#414141',
  leftNavBg: '#fff',
  leftNavFooterBg: 'rgba(227, 238, 246, 0.44)',
  leftNavBorder: '#eceaea',
  textPrimary: '#223345',
  textSecondary: '#5F5F60',
  textDisabled: '#959595',
  drawerText: '#0B0C0D',
  buttonText: '#0A0E0D',
  nightGreen: '#2b2e2b',
  lightGreen: '#889E8F',
  cardBg: '#F9F9F9',
  btnPrimaryBg: '#000',
  btnSecondaryBg: '#414141',
  productDescriptionColor: '#414141',
  primaryLinkBtn: '#646998',
  outlineButtonHover: 'rgba(34, 51, 69, 0.1)',
  productDetailBorder: 'rgba(34, 51, 69, 0.3)',
  carouselButtonBg: '#C6C6C6',
  listIconPrimary: '#5C4FFF',
  productExtraDetailText: '#fff',
  carouselBtnBg: 'rgba(255, 255, 255, 0.2)',
  primaryBg: '#F5F5F5',
  secondaryBg: '#C3E9C4',
  videoBg: '#000000de',
  videoButtonBg: '#00000042',
  heroText: '#fff',
  hoverBg: '#343434',
  introSectionFirstBg: 'rgb(216, 202, 243)',
  introSectionSecondBg: '#f5d2d9',
  introSectionThirdBg: '#d3e9ff',

  // primary bg
  // TODO search replace with better name
  moss: '#011621', // replaced
  sage: '#889E8F',
  midnightBlue: '#011621',
  rust: '#6C5833',
  lightRust: '#efd3b5',
  nightRust: '#61361e',
  brush: '#6A7B56',
  earth: '#DED3C4',
  saintpaddy: '#919A86',
  chicagoGrey: '#595958',
  pmallBgCard: '#9D938F',
  pdpSecondaryBgColor: '#fff',

  seastone: '#cdd1ce',
  seastone2: '#e0e2e0',
  foam: '#e2e6e3',
  foam2: '#e9ebe7',
  charcoal: '#000000',
  offwhite: '#DED3C3', // replaced
  offwhite2: '#f2f2ef',
  offwhite3: '#f7f7f7',
  whiteSands: '#fcfcf9',
  morning: '#9eacae',
  showers: '#cee2ea',
  bigStone: '#364146',

  // form colors
  formError: '#dc3545',
  formHelperText: '#888',
  formIcon: '#2b2e2b',
  // Separator
  separator: '#5c5c5c',
  //badge
  tomato: '#f06570',
  // checkout
  sectionSeparator: '#202020',
  blackPearl: '#20272B',
  laurelGreen: '#258d03',
  checkoutText: '#152B2A',
  checkoutHeaderText: '#000',
  disabledNav: '#acacac',
  subHeading: '#999999',
  selectedAddressBg: 'rgba(110, 123, 112, 0.1)',
  modalBg: 'rgba(205, 209, 206,0.8)',
  modalWindow: '#223345',
  modalText: '#fff',
  checkMark: '#3d3f43',
  darkColor: '#1D2B2B',
  successBagdeColor: '#4D5549',
  lightGray: '#F9F9F9',
  topNavDarkBorder: '#292929',
  greySelectBorder: '#ACAFAD',
  detailsText: '#525F53',
  seaMist: '#C6CCC7',
  darkOrange: '#D0331F',
  cartIntroText: '#5F5F60',
  // meridian
  sandstone: '#EDEBDF',
  stone: '#DAE1E2', // replaced
  aboutBorder: '#CFD1C8',
  eggShell: '#F2F1EF',
  grapeFruit: '#E04713', // replaced
  secondaryTextColor: '#0E1E26',
  tertiaryTextColor: '#263339',
  greyTextColor: '#3d4246',
  darkGreyTextColor: '#333',
  dimGray: '#707070',
  whiteSmoke: '#F8F8F8',
  rustHover: '#E6CCB5',
  quaternaryTextColor: '#8d8e86',
  secondaryBgColor: '#FBF9FF',
  darkBlueColor: '#021523',
  /* checkout , please don't change or remove these values ,
   it's to unify color names used in checkout page across brands*/
  checkoutBgColor: '#F5F5F5',
  drawerTextColor: '#223345',
  drawerBgColor: '#fff',
  cartButtonColor: '#fff',
  cartItemBgColor: '#F9F9F9',
  /* account system , please don't change or remove these values ,
   it's to unify color names used in account system pages across brands*/
  tertiaryTextColorAS: '#263339',
  inputBgColor: '#011621',
  arrowColor: '#6f7780',
  successColor: '#489769',
  backLinkColor: '#848484',
  warningColor: '#b12704',
  editButtonColor: '#54725b',
  successMarkBorder: 'rgba(110,123,112,0.37)',
  wildSand: '#f4f4f4',
  itemBgColor: '#eae8e9',
  vermilion: '#F44C13',
  pink: '#ffd4f0',
  purple: '#d4c5ec',
  // Professional
  professionalBg: '#495867',
  professionalName: '#fff',
  professionalText: '#414141',
  subscribeBgButton: '#414141',
  subscribeBgButtonHover: '#343434',
  subscribeTextButton: 'white',
  // FAQ
  faqText: '#414141',
  faqBgButton: '#414141',
  faqBgButtonHover: '#343434',
  faqTextButton: 'white',
  megaMenuColor: '#414141',
  megaMenuBgColor: 'white',
  goldStarsColor: '#ffb829',
  reviewAvatarBg: '#f0b9a5',
  // Discount badge
  flowerIconOuterBg: '#aaf8ab',
  flowerIconInnerBg: '#f8d6eb',
}

const shadows = {
  sm: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
  md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
  xl: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
  '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
  outline: '0 0 0 3px rgba(66, 153, 225, 0.6)',
  inner: 'inset 0 2px 4px 0 rgba(0,0,0,0.06)',
  topNavShadow: '0 4px 7px 1px rgb(0 0 0 / 8%)',
  addressBlockShadow:
    '1px 0 1px rgba(0, 0, 0, 0.2), -1px 0 1px rgba(0, 0, 0, 0.19)',
  inputShadow: 'rgb(0, 100, 205) 0px 0px 5px',
  none: 'none',
  cartShadow: '5px 0px 10px rgba(0, 0, 0, 0.1)',
  heroSectionButtonShadow: '0 3px 5px 1px rgb(0 0 0 / 10%)',
  mediaBoxShadow: '0 0 .62rem white',
  faqButtonShadow: '0 3px 5px 1px rgb(0 0 0 / 10%)',
  faqButtonShadowHover: '2px 3px 5px 1px rgb(0 0 0 / 20%)',
  primButtonShadow: '2px 3px 5px 1px rgb(0 0 0 / 20%)',
  subscribeButtonShadow: '0 3px 5px 1px rgb(0 0 0 / 10%)',
  subscribeButtonShadowHover: '2px 3px 5px 1px rgb(0 0 0 / 20%)',
  pdpCarouselShadow: '0 4px 10px 1px rgb(0 0 0 / 10%)',
  megaMenuShadow: '0 4px 7px 1px rgb(0 0 0 / 8%)',
  innerFlowerIconShadow: '0 0 5px #f8d6eb',
  outerFlowerIconShadow1: '0 6px 5px rgba(0,0,0,.2)',
  outerFlowerIconShadow2: '4px 4px 5px rgba(0,0,0,.2)',
}

const breakpoints = ['768px', '992px', '1300px']

const zIndices = {
  hide: -1,
  auto: 'auto',
  base: 0,
  header: 1,
  docked: 10,
  dropdown: 1000,
  sticky: 1100,
  banner: 1200,
  overlay: 1300,
  modal: 1400,
  popover: 1500,
  skipLink: 1600,
  toast: 1700,
  tooltip: 1800,
  topBar: 1101,
}

const radii = {
  none: '0',
  sm: '0.125rem',
  md: '0.25rem',
  lg: '0.5rem',
  round: '50%',
  full: '9999px',
}

const opacity = {
  '0': '0',
  '20%': '0.2',
  '40%': '0.4',
  '60%': '0.6',
  '80%': '0.8',
  '100%': '1',
}

const borders = {
  none: 0,
  '1px': '1px solid',
  '2px': '2px solid',
  '4px': '4px solid',

  disabled: `1px solid ${colors.disabled}`,
  nightGreen: `1px solid ${colors.nightGreen}`,
  whiteSands: `1px solid ${colors.whiteSands}`,
  seastone: `1px solid ${colors.seastone}`,
  moss: `1px solid ${colors.moss}`,
  morning: `1px solid ${colors.morning}`,
  about: `1px dashed ${colors.aboutBorder}`,
  aboutSolid: `1px solid ${colors.aboutBorder}`,
  rust: `1px solid ${colors.rust}`,
  dimGrayDashed: `1px dashed ${colors.dimGray}`,
  productDetail: `.4px solid ${colors.productDetailBorder}`,
  white: `2px solid ${colors.white}`,
}

const durations = {
  instant: '0s',
  quick: '0.2s',
  default: '0.4s',
  slow: '0.6s',
  slower: '1.2s',
}

const easings = {
  default: 'ease-in-out',
}

const transitions = {
  allEase: 'all ease 500ms',
  cspEase: 'opacity ease 500ms',
  opacityEase: 'opacity .3s ease',
  marquee: '15s linear infinite',
}

const logoUrl = '/img/logos/zitsticka-logo.svg'
const whiteLogoUrl = '/img/logos/zitsticka-white-logo.svg'

const globalStyles = {
  color: `rgb(30, 45, 43)`,
  fontFamily: 'regular',

  '.logo-container': {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  '.brand-logo': {
    backgroundImage: `url(${logoUrl})`,
    width: '12.5rem',
    height: '1.5rem',
  },
  '.sm\\:brand-logo': {
    backgroundImage: `url(${logoUrl})`,
    width: '6.313rem',
    height: '0.688rem',
    backgroundSize: 'cover',
  },
  '.footer-brand-logo': {
    backgroundImage: `url(${logoUrl})`,
    width: '12.5rem',
    height: '1.375rem',
  },
  'a:hover': {
    cursor: 'pointer',
  },
}

const customTheme = {
  styles: {
    global: globalStyles,
  },
  breakpoints,
  zIndices,
  radii,
  opacity,
  borders,
  colors,
  durations,
  easings,
  sizes,
  shadows,
  space: {
    ...space,
    textualPageSpacing: ['5rem', '5.5rem', '8rem'],
    accountPageSpacing: ['5rem', '7rem', '10.5rem'],
    starsSpacing: '.1rem',
  },
  icons,
  components,
  transitions,
  ...typography,
}

export type ZitstickaNewTheme = typeof customTheme

export default customTheme
