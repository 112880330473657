import { GLOBAL_CMS_ID } from './constants'
import { CmsPageDataException } from './exceptions/CmsPageDataException'

import config from 'core/config'
import { apiFetchJson, getBrandTenantKey } from 'modules/api'
import { JsonObject } from 'utils/types'

export function cmsFetch(brand: string, path: string, opts?: RequestInit) {
  const headers = opts?.headers || {}
  const options = opts ? { ...opts } : {}

  headers['tenant'] = getBrandTenantKey(brand)
  options.headers = headers
  options.credentials = 'include'

  return apiFetchJson(path, options, config('ADMIN_BASEURL'))
}

export async function saveCmsPage(
  brand: string,
  pageId: string,
  data: JsonObject
) {
  const ret = await cmsFetch(brand, `/api/cms/page/${pageId}`, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify({ data }),
  })
  return ret
}

export async function saveCmsGlobalComponent(
  brand: string,
  componentId: string,
  data: JsonObject
) {
  const ret = await cmsFetch(brand, `/api/cms/page/${GLOBAL_CMS_ID}`, {
    credentials: 'include',
    method: 'PATCH',
    body: JSON.stringify({ data: { [componentId]: data } }),
  })
  return ret
}

export async function getCmsLoggedStatus(brand: string) {
  const res = await cmsFetch(brand, '/api/cms/me')

  if (!res.id) {
    return false
  }

  return true
}

export async function getCmsPageData(brand: string, pageId: string) {
  const key = getBrandTenantKey(brand)
  const baseUrl = config('STOREFRONT_API_BASEURL')

  const ret = await apiFetchJson(
    `/api/cms/page/${pageId}`,
    { headers: { 'pangaea-brand-public-key': key } },
    baseUrl
  )

  if (ret.isError) {
    throw new CmsPageDataException(
      'Error occurred on fetching cms page caller=getCmsPageData, ' +
        `brand=${brand}, pageId=${pageId}`,
      ret
    )
  }

  return ret.result?.data || {}
}

export async function getCmsGlobalData(brand: string) {
  try {
    return await getCmsPageData(brand, GLOBAL_CMS_ID)
  } catch (e) {
    if (
      e instanceof CmsPageDataException &&
      e.getData().name === 'NotFoundError'
    ) {
      return {}
    }

    throw e
  }
}

export async function getSitePolicyContent(brand: string) {
  const res = await cmsFetch(brand, '/api/site-policy/content')

  return res || {}
}

export async function getCmsSignedUrl(brand: string, fileName: string) {
  const res = await cmsFetch(brand, '/api/cms/sign-upload', {
    method: 'POST',
    body: JSON.stringify({ file_name: fileName }),
  })

  return res.result || {}
}
