const baseStyle = {
  fontFamily: 'regular',
  fontWeight: 'normal',
}

const sizes = {
  lg: {
    fontSize: ['1rem', '1.5rem', '2rem'],
    lineHeight: ['1.06rem', null, '1.125rem'],
  },
  md: {
    fontSize: ['1.5rem', '2rem', '3rem'],
    lineHeight: ['1.125em', null, '1.25em'],
  },
  sm: {
    fontSize: ['1.5rem', null, '2.25rem'],
    lineHeight: ['1.125em', null, '1.333em'],
  },
  xs: {
    fontSize: ['0.875rem', null, '1.5rem'],
    lineHeight: ['0.75em', null, '1em'],
  },
  xl: {
    fontSize: ['1rem', '2rem', '3rem'],
    lineHeight: ['auto'],
  },
  '2xl': {
    fontSize: ['1.5rem', '2.5rem', '4rem'],
    lineHeight: ['auto', '2.5rem', '4rem'],
  },
}

const defaultProps = {
  size: 'lg',
}

const variants = {
  cardHeading: {
    fontWeight: 'bold',
    fontSize: ['1rem', '1.25rem', '1.5rem'],
    lineHeight: ['auto'],
    color: 'textPrimary',
  },
  sectionHeading: {
    fontSize: sizes.lg.fontSize,
    lineHeight: sizes.lg.lineHeight,
    fontWeight: 'semibold',
    color: 'textPrimary',
    _disabled: {
      color: 'textDisabled',
    },
  },
  heroHeading: {
    fontSize: ['1.5rem', '2.5rem', '3.125rem'],
    lineHeight: sizes['2xl'].lineHeight,
    color: 'heroText',
    fontWeight: 'bold',
  },
  faqTitle: {
    color: 'faqText',
    fontFamily: 'bold',
    textAlign: 'center',
    fontSize: '1.35rem',
    lineHeight: '2.25rem',
    letterSpacing: ['.1rem', '.18rem'],
    textTransform: 'uppercase',
  },

  h1: {
    fontSize: ['1.5rem', '2.5rem', '4rem'],
    lineHeight: ['auto', '2.5rem', '4rem'],
  },
  h2: {
    fontSize: ['1.5rem', '2rem', '3rem'],
    lineHeight: ['1.125em', null, '1.25em'],
  },
  h3: {
    fontSize: ['1rem', '2rem', '3rem'],
    lineHeight: ['auto'],
  },
  h4: {
    fontSize: ['1.5rem', null, '2.25rem'],
    lineHeight: ['1.125em', null, '1.333em'],
  },
  h5: {
    fontSize: ['1rem', '1.5rem', '2rem'],
    lineHeight: ['1.06rem', null, '1.125rem'],
  },
  h6: {
    fontSize: ['0.875rem', null, '1.5rem'],
    lineHeight: ['0.75em', null, '1em'],
  },
}

const Heading = {
  defaultProps,
  baseStyle,
  sizes,
  variants,
}

export { Heading }
