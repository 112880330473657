import { fetchSettings } from '@pangaea-holdings/pangaea-account'
import { useEffect } from 'react'

import { useTypedDispatch } from '../../../redux/store'
import { TENANT_KEYS } from '../../api/index'
import { loadAuthFromCookie, loginStatusChecked } from '../actions'

export const useAccountAuth = (brand: keyof typeof TENANT_KEYS) => {
  const dispatch = useTypedDispatch()

  useEffect(() => {
    ;(async () => {
      if (brand) {
        try {
          await fetchSettings()
          dispatch(loadAuthFromCookie())
        } catch (err) {
          dispatch(loginStatusChecked())
          // if err, this could mean:
          // 1- The user didn't login before
          // 2- The user already logged in another brand and is not logged in the currently selected brand
          // 3- The current userToken is invalid for any other reason
          // in all the above cases the dispatch(loadAuthFromCookie()) won't be called,
          // so the user will be asked to enter his email when he tries to open the login/account drawer
        }
      }
    })()
  }, [brand])
}
