import { Heading, HeadingProps, BoxProps, Button, Box } from '@chakra-ui/core'
import React, { FC, ReactElement, isValidElement } from 'react'

import CloseIcon from '../../../modules/shared/components/Icons/CloseIcon'
import styled from '../../styled'
import ModalBase from './ModalBase'

const sizes = {
  xs: '400px',
  sm: '500px',
  md: '745px',
  lg: '940px',
  xl: '1170px',
}

const ModalBg = styled(Box)(({ theme }) => ({
  zIndex: theme.zIndices.modal,
  position: 'fixed' as 'fixed',
  height: '100%',
  width: '100%',
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  'body.ios &': {
    paddingTop: 'calc(env(safe-area-inset-top) + 50px)',
  },
}))

const ModalFg = styled(Box)(({ theme }) => ({
  position: 'relative',
  flex: '0 0 auto',
  display: 'flex',
  flexDirection: 'column',
  padding: `${theme.space[10]} ${theme.space[6]}`,
  borderRadius: theme.radii.md,
  boxShadow: theme.shadows.md,
  maxWidth: '95vw',
  maxHeight: '95vh',
  '.modal-enter &': {
    transform: 'scale(0)',
  },
  '.modal-enter.modal-enter-active &': {
    transform: 'scale(1)',
    transition: `transform ${theme.durations.default} ${theme.easings.default}`,
  },
  '.modal-leave &': {
    transform: 'scale(1)',
  },
  '.modal-leave.modal-leave-active &': {
    transform: 'scale(0)',
    transition: `transform ${theme.durations.default} ${theme.easings.default}`,
  },
}))

type HeaderProps = HeadingProps & {
  header?: string | ReactElement<any> | false
}

const Header: React.FC<HeaderProps> = ({ header, ...headingProps }) => {
  switch (typeof header) {
    case 'undefined':
    case 'boolean':
      return null

    case 'string':
      return (
        <Heading size="sm" {...headingProps}>
          {header}
        </Heading>
      )

    default:
      return header
  }
}

const ModalHeader = styled(Header)(() => ({
  flex: '0 0 auto',
  alignItems: 'center',
  height: '3rem',
  width: '100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontWeight: 'normal',
}))

const ModalCloseButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  width: '2rem !important',
  top: theme.space[4],
  right: '0px',
  border: 'none',
  color: 'textPrimary',
}))

const ModalFooter = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.colors.seastone}`,
  height: '3rem',
}))

type ContentProps = BoxProps & {
  hasFooter: boolean
  hasHeader: boolean
}

const ModalContent = styled(Box)<ContentProps>(
  ({ hasFooter, hasHeader, theme }) => ({
    flex: '1 0 auto',
    paddingTop: theme.space[2],
    maxHeight: ` calc(
    95vh
    - ${hasHeader ? '3rem' : '0px'}
    - ${hasFooter ? '3rem' : '0px'}
    - ${theme.space[20]}
  ) `,
    borderBottom: hasFooter ? `1px solid ${theme.colors.white}` : 'none',
  })
)

interface ModalProps {
  header?: HeaderProps['header']
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  /**
   * The footer has a hard height of 2em
   */
  footer?: ReactElement<any> | false
  closeButton?: ReactElement<any> | boolean
  isOpen: boolean
  setIsOpen: (val: boolean) => void
  bgColor?: string
}

const Modal: FC<ModalProps> = ({
  header,
  size = 'sm',
  footer,
  children,
  closeButton,
  isOpen,
  setIsOpen,
  bgColor = 'white',
}) => {
  const close = (() => {
    if (closeButton === false) {
      return null
    }

    if (isValidElement(closeButton)) {
      return closeButton
    }

    return (
      <ModalCloseButton variant="blank" onClick={() => setIsOpen(false)}>
        <CloseIcon />
      </ModalCloseButton>
    )
  })()

  return (
    <ModalBase isOpen={isOpen} setIsOpen={setIsOpen}>
      <ModalBg key={'container'} onClick={() => setIsOpen(false)}>
        <ModalFg
          onClick={(e) => e.stopPropagation()}
          w={sizes[size]}
          bgColor={bgColor}
        >
          {close}
          <ModalHeader header={header} />
          <ModalContent
            hasFooter={!!footer}
            hasHeader={!!header}
            overflowY="auto"
          >
            {children}
          </ModalContent>
          {footer && <ModalFooter>{footer}</ModalFooter>}
        </ModalFg>
      </ModalBg>
    </ModalBase>
  )
}

export default Modal
