import { Button, ButtonProps } from '@chakra-ui/core'
import React from 'react'

import { OutlineCloseIcon } from '../../../modules/shared/components/Icons/OutlineClose'
import { Anchor } from './types'

export type DrawerCloseProps = Omit<ButtonProps, 'children'> & {
  anchor?: Anchor
  removeSelf: () => void
}

const DrawerClose: React.FC<DrawerCloseProps> = ({
  anchor = 'left',
  removeSelf,
  ...buttonProps
}) => {
  return (
    <Button
      variant="blank"
      borderRadius="full"
      onClick={() => removeSelf()}
      width="2rem"
      height="2rem"
      {...buttonProps}
    >
      <OutlineCloseIcon w="0.75rem" h="0.75rem" />
    </Button>
  )
}

export default DrawerClose
