import { AnyDict } from '../../utils/types'
import { trackExperimentActivated } from './events'

export const optimizelyPush = (data: AnyDict) => {
  window['optimizely'] = window['optimizely'] || []
  window['optimizely'].push(data)
}

/**
 * This activates a "page" that is setup for manual activation
 * https://help.optimizely.com/Build_Campaigns_and_Experiments/Activate_pages_in_Optimizely_X#Manually
 * https://docs.developers.optimizely.com/web/docs/page
 *
 * This is useful for triggering specific experiments manually that are associated with this page
 */
export const activateOptimizelyPage = async (
  pageName: string
): Promise<void> => {
  return new Promise((resolve) => {
    optimizelyPush({
      type: 'page',
      pageName,
    })

    trackExperimentActivated(pageName)
    // set timeout is used to give the optimizely page activation time to "activate" and call the variation code
    setTimeout(() => {
      resolve()
    }, 0)
  })
}
