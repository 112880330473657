const zeroDecimalCurrencies = [
  'BIF',
  'MGA',
  'CLP',
  'DJF',
  'PYG',
  'RWF',
  'GNF',
  'JPY',
  'KMF',
  'KRW',
  'UGX',
  'VND',
  'VUV',
  'XAF',
  'XOF',
  'XPF',
]

export const TOP_CURRENCIES = {
  USD:"USD",
  EUR:"EUR",
  ILS:"ILS",
  AED:"AED", 
  SAR:"SAR", 
  QAR:"QAR",
  SGD:"SGD"
}

export function formatCurrencyInteger(
  amount,
  currency,
  fractionDigits = 2,
  language = 'en-US'
): string {
  // TODO add language support
  fractionDigits = zeroDecimalCurrencies.includes(currency) ? 0 : fractionDigits

  return new Intl.NumberFormat(language, {
    style: 'currency',
    currency: currency.toUpperCase(),
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(amount / 100)
}
