import productRoutes from '../products/routes'
import buildRoutes from '../shared/buildRoutes'

import { checkoutRoutes } from 'modules/checkout/routes'

const accountRoutes = buildRoutes('pages/accounts', {
  accounts: '',
  orders: 'orders',
  subscriptions: 'subscriptions',
  warranties: 'warranties',
  homePage: () => productRoutes.products({ scroll: false }),
  checkout: checkoutRoutes.checkout,
  giftCardOrders: 'gift-card-orders',
})
export default accountRoutes
