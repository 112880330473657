type Dict = Record<string, any>

const parts = {
  errorText: 'the error message',
  errorIcon: 'the error icon',
  requiredIndicator: 'the requied asterisks',
  helperText: 'the helper text',
}

function baseStyleErrorText(props: Dict) {
  return {
    color: 'formError',
    fontSize: 'sm',
  }
}

const baseStyle = (props: Dict) => ({
  errorText: baseStyleErrorText(props),
})

const Form = {
  parts,
  baseStyle,
}

export default Form
