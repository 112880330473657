import { JsonObject } from 'utils/types'

export class CmsPageDataException<D extends JsonObject = {}> extends Error {
  private data: D

  constructor(message: string, data: D) {
    super(message)

    this.name = 'CmsPageDataException'
    this.data = data
  }

  getData(): Readonly<D> {
    return Object.freeze(this.data)
  }
}
