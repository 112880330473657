import { softAssertNever } from '../../utils/types'
import { CartDrawerActionTypes, CartDrawerActions } from './actionTypes'

export type CartDrawerState = {
  isOpen: boolean
}

const initialState: CartDrawerState = {
  isOpen: false,
}

export function cartDrawerReducer(
  state = initialState,
  action: CartDrawerActionTypes
): CartDrawerState {
  switch (action.type) {
    case CartDrawerActions.OPEN_CART_DRAWER:
      return { ...state, isOpen: true }
    case CartDrawerActions.CLOSE_CART_DRAWER:
      return { ...state, isOpen: false }
    default:
      softAssertNever(action)
      return state
  }
}
