export enum BrandActions {
  SET_CURRENT_BRAND = 'BRAND_SET_CURRENT_BRAND',
}

export interface BrandSetCurrentBrandAction {
  type: BrandActions.SET_CURRENT_BRAND
  payload: string | undefined
}

export type BrandActionTypes = BrandSetCurrentBrandAction

export interface BrandReducerState {
  brand?: string
}
