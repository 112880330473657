import { useEffect, useContext } from 'react'
import { Block } from 'react-tinacms-inline'
import { useCMS } from 'tinacms'

import { useIsMountedRef } from '../../../utils/hooks'
import { getCmsLoggedStatus } from '../api'
import { GlobalCMSContext } from '../context'

import { selectBrand } from 'modules/brand/selectors'
import { useTypedSelector } from 'redux/store'

export interface BlockConfig {
  [key: string]: Block
}

let fetchedLoginStatus = false

export default function useCMSLoggedStatus() {
  const brand = useTypedSelector(selectBrand())
  const { setIsLoggedToCMS } = useContext(GlobalCMSContext)

  const isMounted = useIsMountedRef()

  useEffect(() => {
    if (!brand) {
      return
    }
    if (!fetchedLoginStatus) {
      fetchedLoginStatus = true

      getCmsLoggedStatus(brand).then((isLoggedIn) => {
        if (isMounted.current) {
          setIsLoggedToCMS(isLoggedIn)
        }
      })
    }
  }, [brand])
}

export function useCheckCMSStatus() {
  const cms = useCMS()
  const { isLoggedToCMS } = useContext(GlobalCMSContext)
  useCMSLoggedStatus()
  useEffect(() => {
    if (!isLoggedToCMS) {
      if (cms.enabled) {
        cms.disable()
      }
      return
    }
    if (!cms.enabled) {
      cms.enable()
    }
    return () => {
      cms.disable()
    }
  }, [isLoggedToCMS])

  return cms
}
