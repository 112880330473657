import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux'
import {
  createStore as reduxCreateStore,
  applyMiddleware,
  Middleware,
  compose,
  ActionCreator,
} from 'redux'
import thunk, { ThunkDispatch, ThunkAction } from 'redux-thunk'

import { rootReducer, AppState, AppActionTypes } from './rootReducer'

export function createStore() {
  const middlewares: Middleware[] = [thunk]
  const composeEnhancers =
    (process.browser &&
      typeof window !== 'undefined' &&
      ((window as any)
        .__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ as typeof compose)) ||
    compose

  return reduxCreateStore(
    rootReducer,
    undefined,
    composeEnhancers(applyMiddleware(...middlewares))
  )
}

export const useTypedSelector: TypedUseSelectorHook<AppState> = useSelector

export const useTypedDispatch: () => ThunkDispatch<
  AppState,
  void,
  AppActionTypes
> = () => {
  const dispatch: ThunkDispatch<AppState, {}, AppActionTypes> = useDispatch()
  return dispatch
}

export type AppActionCreator = ActionCreator<AppActionTypes>

export type ThunkAppActionCreator<R> = ActionCreator<
  ThunkAction<R, AppState, void, AppActionTypes>
>
