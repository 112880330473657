export enum CartDrawerActions {
  OPEN_CART_DRAWER = 'OPEN_CART_DRAWER',
  CLOSE_CART_DRAWER = 'CLOSE_CART_DRAWER',
}

export interface OpenCartDrawerAction {
  type: CartDrawerActions.OPEN_CART_DRAWER
}

export interface CloseCartDrawerAction {
  type: CartDrawerActions.CLOSE_CART_DRAWER
}

export type CartDrawerActionTypes = OpenCartDrawerAction | CloseCartDrawerAction
