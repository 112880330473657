import { TraitValue } from '@pangaea-holdings/analytics-user-state'
import { PromotionSource } from '@pangaea-holdings/pangaea-checkout'

import { layerPush } from '../analytics/functions/track'

enum EVENTS {
  SUBSCRIBE_TO_MAILING_LIST = 'subscribeToMailinglist',
  IDENTIFIED_USER_TRAITS = 'identifiedUserTraits',
  PROMOTION_APPLIED = 'promotionApplied',
}

export const trackSubscribeToMailinglist = (email: string) => {
  layerPush({
    event: EVENTS.SUBSCRIBE_TO_MAILING_LIST,
    acceptsEmailMarketing: true,
    email,
  })
}

export const trackUserTraits = (traits: Record<string, TraitValue>) => {
  layerPush({
    event: EVENTS.IDENTIFIED_USER_TRAITS,
    traits,
  })
}

/**
 * Tracks Promotion Applied.
 */
export const trackPromotionApplied = (promotionSource: PromotionSource) => {
  return layerPush({
    event: EVENTS.PROMOTION_APPLIED,
    promotion_source: promotionSource,
  })
}
