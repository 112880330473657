import {
  activateOptimizelyPage,
  CartContents,
  setTrackingFunctions,
} from '@pangaea-holdings/pangaea-checkout'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Drawer } from '../../../../design/components'
import { COOKIE_DISCOUNT_CODE, getCookie } from '../../../../utils/cookie'
import { useBrandedRoutes } from '../../../brand/hooks/useBrandedRoutes'
import TextField from '../../../cms/fields/Text'
import { WithGlobalCMSOption } from '../../../cms/hoc/WithGlobalCMSOption'
import productRoutes from '../../../products/routes'
import { openCartDrawer, closeCartDrawer } from '../../actions'
import { FreeShippingBanner } from './FreeShippingBanner'

import * as track from 'modules/analytics/functions/track'
import { useFeatureFlag } from 'modules/optimizely/hooks/useFeatureFlag'
import { useTypedDispatch } from 'redux/store'

export interface CartDrawerProps {
  isOpen: boolean
  cmsValues?: {
    isEnabled: boolean
    bannerText: string
    bgColor: string
    textColor: string
  }
}

const CartDrawer: React.FC<CartDrawerProps> = ({ isOpen, cmsValues }) => {
  const [overrideCoupon, setOverrideCoupon] = useState('')
  const [freeShipFlagActivated, setFreeShippingFlagActivated] = useState(false)
  const dispatch = useTypedDispatch()
  const enableCouponFieldOnCart: boolean = useFeatureFlag(
    'ENABLE_COUPON_FIELD_ON_CART',
    false
  )
  const { t } = useTranslation()
  const otpDescription = t('checkout:one-time-purchase', 'One time purchase')
  const subscriptionDescription = t(
    'checkout:one-month-subscription',
    'Shipped every 30 days'
  )

  const setIsCartDrawerOpen = (isCartOpen: boolean) => {
    if (isCartOpen) {
      dispatch(openCartDrawer())
    } else {
      dispatch(closeCartDrawer())
    }
  }

  useEffect(() => {
    setOverrideCoupon(getCookie(COOKIE_DISCOUNT_CODE))
  }, [])

  useEffect(() => {
    setTrackingFunctions(track)
  }, [])


  useEffect(() => {
    if (isOpen && !freeShipFlagActivated) {
      ;(async () => {
        await activateOptimizelyPage('free_shipping_controller')
        setFreeShippingFlagActivated(true)
      })()
    }
  }, [isOpen])

  const routes = useBrandedRoutes(productRoutes)
  const router = useRouter()

  const onClickProductHandler = async (slug: string) => {
    const route = routes.detail({ slug })
    await router.push(route)
  }

  return (
    <Drawer
      anchor="right"
      isOpen={isOpen}
      setIsOpen={setIsCartDrawerOpen}
      closeButton={false}
      desktopWidth={450}
      backgroundColor="drawerBgColor"
      color="drawerTextColor"
    >
      <CartContents
        otpDescription={otpDescription}
        subscriptionDescription={subscriptionDescription}
        onCartProductClick={onClickProductHandler}
        setIsOpen={setIsCartDrawerOpen}
        overrideCoupon={overrideCoupon}
        enableCouponOnCart={enableCouponFieldOnCart}
        mx={['0.4rem', '0.875rem']}
        my={['0.25rem', '0.5625rem']}
        cartItemStyle={{
          bgColor: 'cartItemBgColor',
          borderRadius: '0.5rem',
        }}
        cartButtonStyle={{
          height: ['3.938rem', 'auto', 'auto'],
        }}
        cartButtonTextStyle={{
          fontSize: ['.9rem', '.75rem', '.75rem'],
        }}
        headingComponent={
          <FreeShippingBanner
            w={['calc(100% + 2.8rem)', 'calc(100% + 3.75rem)']}
            cmsValues={cmsValues}
            my="1.5rem"
            h="2rem"
            fontSize="1.5rem"
            d="flex"
            alignItems="center"
            justifyContent="center"
          />
        }
      />
    </Drawer>
  )
}

export default WithGlobalCMSOption(CartDrawer, 'cartConfig', {
  label: 'Free ship banner',
  fields: [
    { name: 'isEnabled', label: 'Enabled', component: 'toggle' },
    { name: 'bgColor', label: 'Background color', component: 'color' },
    { name: 'textColor', label: 'Text color', component: 'color' },

    {
      name: 'bannerText',
      label: 'Banner text',
      component: TextField,
      availableVariables: {
        defaultText: "You've got FREE Shipping!",
      },
    },
  ],
})
