import { useRef, useMemo, useEffect } from 'react'

import { useTypedSelector } from '../../../redux/store'
import { BuiltRoutes } from '../../shared/buildRoutes'
import { selectBrand } from '../selectors'

export function addBrandToRoutes<T extends BuiltRoutes<any>>(
  routes: T,
  brand?: string
): T {
  const addBrand = (route: string): string => {
    return `${brand ? '/' + brand : ''}${route}`
  }

  return Object.entries(routes)
    .map(([key, gen]): [typeof key, typeof gen] => [
      key,
      (...args: Parameters<typeof gen>) => addBrand(gen(...args)),
    ])
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {} as T)
}

/**
 * Returns a version of the routes that is prefixed with the current brand.
 */
export function useBrandedRoutes<T extends BuiltRoutes<any>>(routes: T): T {
  const brand = useTypedSelector(selectBrand())
  const brandRef = useRef(brand)

  const ret = useMemo(
    () => addBrandToRoutes(routes, brandRef.current),
    [routes]
  )

  useEffect(() => {
    brandRef.current = brand
  }, [brand])

  return ret
}
