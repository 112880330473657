import { createContext, useReducer } from 'react'

import { assertNever } from '../../../utils/types'
import { ANALYTICS_PAGE_TYPES } from '../types'

export interface AnalyticsState {
  pageType?: ANALYTICS_PAGE_TYPES
}

export interface AnalyticsSetPageTypeAction {
  type: 'ANALYTICS_SET_PAGE_TYPE'
  payload?: ANALYTICS_PAGE_TYPES
}

export type AnalyticsActions = AnalyticsSetPageTypeAction

interface AnalyticsContextValue {
  state: AnalyticsState
  dispatch: React.Dispatch<AnalyticsActions>
}

const initialAnalyticsState: AnalyticsState = {}

export const AnalyticsContext = createContext<AnalyticsContextValue>({
  state: initialAnalyticsState,
  dispatch: () => { throw new Error('dispatch not initialized') },
})

function reducer(state: AnalyticsState, action: AnalyticsActions): AnalyticsState {
  switch(action.type) {
    case 'ANALYTICS_SET_PAGE_TYPE':
      return { ...state, pageType: action.payload }

    default:
      assertNever(action.type)
  }
}

const AnalyticsProvider:React.FC<{}> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAnalyticsState)

  return (
    <AnalyticsContext.Provider value={{ state, dispatch} }>
      {children}
    </AnalyticsContext.Provider>
  )
}

export default AnalyticsProvider
