import Head from 'next/head'
import React from 'react'

import config from '../../../core/config'

/**
 * Loads the Osano consent manager script. Must be loaded before other external
 * scripts.
 */
const OsanoScriptTag: React.FC = () => {
  const osanoScriptUrl = config('OSANO_SCRIPT_URL')

  if (!osanoScriptUrl) {
    return null
  }

  return (
    <Head key="osano_script">
        <script type="text/javascript" src={osanoScriptUrl}></script>
    </Head>
  )
}

export default OsanoScriptTag
