// Todo: Save and read this from db on service store front

export interface CurrencyData {
  currency: string
  country: string
  currency_code: string
}

export const CURRENCIES: { [countryCode: string]: CurrencyData } = {
  NZ: {
    currency: 'New Zealand Dollars',
    country: 'New Zealand',
    currency_code: 'NZD',
  },
  CK: {
    currency: 'New Zealand Dollars',
    country: 'Cook Islands',
    currency_code: 'NZD',
  },
  NU: {
    currency: 'New Zealand Dollars',
    country: 'Niue',
    currency_code: 'NZD',
  },
  PN: {
    currency: 'New Zealand Dollars',
    country: 'Pitcairn',
    currency_code: 'NZD',
  },
  TK: {
    currency: 'New Zealand Dollars',
    country: 'Tokelau',
    currency_code: 'NZD',
  },
  AU: {
    currency: 'Australian Dollars',
    country: 'Australian',
    currency_code: 'AUD',
  },
  CX: {
    currency: 'Australian Dollars',
    country: 'Christmas Island',
    currency_code: 'AUD',
  },
  CC: {
    currency: 'Australian Dollars',
    country: 'Cocos (Keeling) Islands',
    currency_code: 'AUD',
  },
  HM: {
    currency: 'Australian Dollars',
    country: 'Heard and Mc Donald Islands',
    currency_code: 'AUD',
  },
  KI: {
    currency: 'Australian Dollars',
    country: 'Kiribati',
    currency_code: 'AUD',
  },
  NR: {
    currency: 'Australian Dollars',
    country: 'Nauru',
    currency_code: 'AUD',
  },
  NF: {
    currency: 'Australian Dollars',
    country: 'Norfolk Island',
    currency_code: 'AUD',
  },
  TV: {
    currency: 'Australian Dollars',
    country: 'Tuvalu',
    currency_code: 'AUD',
  },
  AS: { currency: 'Euros', country: 'American Samoa', currency_code: 'EUR' },
  AD: { currency: 'Euros', country: 'Andorra', currency_code: 'EUR' },
  AT: { currency: 'Euros', country: 'Austria', currency_code: 'EUR' },
  BE: { currency: 'Euros', country: 'Belgium', currency_code: 'EUR' },
  FI: { currency: 'Euros', country: 'Finland', currency_code: 'EUR' },
  FR: { currency: 'Euros', country: 'France', currency_code: 'EUR' },
  GF: { currency: 'Euros', country: 'French Guiana', currency_code: 'EUR' },
  TF: {
    currency: 'Euros',
    country: 'French Southern Territories',
    currency_code: 'EUR',
  },
  DE: { currency: 'Euros', country: 'Germany', currency_code: 'EUR' },
  GR: { currency: 'Euros', country: 'Greece', currency_code: 'EUR' },
  GP: { currency: 'Euros', country: 'Guadeloupe', currency_code: 'EUR' },
  IE: { currency: 'Euros', country: 'Ireland', currency_code: 'EUR' },
  IT: { currency: 'Euros', country: 'Italy', currency_code: 'EUR' },
  LU: { currency: 'Euros', country: 'Luxembourg', currency_code: 'EUR' },
  MQ: { currency: 'Euros', country: 'Martinique', currency_code: 'EUR' },
  YT: { currency: 'Euros', country: 'Mayotte', currency_code: 'EUR' },
  MC: { currency: 'Euros', country: 'Monaco', currency_code: 'EUR' },
  NL: { currency: 'Euros', country: 'Netherlands', currency_code: 'EUR' },
  PT: { currency: 'Euros', country: 'Portugal', currency_code: 'EUR' },
  RE: { currency: 'Euros', country: 'Reunion', currency_code: 'EUR' },
  WS: { currency: 'Euros', country: 'Samoa', currency_code: 'EUR' },
  SM: { currency: 'Euros', country: 'San Marino', currency_code: 'EUR' },
  SI: { currency: 'Euros', country: 'Slovenia', currency_code: 'EUR' },
  ES: { currency: 'Euros', country: 'Spain', currency_code: 'EUR' },
  VA: {
    currency: 'Euros',
    country: 'Vatican City State (Holy See)',
    currency_code: 'EUR',
  },
  GS: {
    currency: 'Sterling',
    country: 'South Georgia and the South Sandwich Islands',
    currency_code: 'GBP',
  },
  GB: { currency: 'Sterling', country: 'United Kingdom', currency_code: 'GBP' },
  JE: { currency: 'Sterling', country: 'Jersey', currency_code: 'GBP' },
  IO: {
    currency: 'USD',
    country: 'British Indian Ocean Territory',
    currency_code: 'USD',
  },
  GU: { currency: 'USD', country: 'Guam', currency_code: 'USD' },
  MH: { currency: 'USD', country: 'Marshall Islands', currency_code: 'USD' },
  FM: {
    currency: 'USD',
    country: 'Micronesia Federated States of',
    currency_code: 'USD',
  },
  MP: {
    currency: 'USD',
    country: 'Northern Mariana Islands',
    currency_code: 'USD',
  },
  PW: { currency: 'USD', country: 'Palau', currency_code: 'USD' },
  PR: { currency: 'USD', country: 'Puerto Rico', currency_code: 'USD' },
  TC: {
    currency: 'USD',
    country: 'Turks and Caicos Islands',
    currency_code: 'USD',
  },
  US: { currency: 'USD', country: 'United States', currency_code: 'USD' },
  UM: {
    currency: 'USD',
    country: 'United States Minor Outlying Islands',
    currency_code: 'USD',
  },
  VG: {
    currency: 'USD',
    country: 'Virgin Islands (British)',
    currency_code: 'USD',
  },
  VI: { currency: 'USD', country: 'Virgin Islands (US)', currency_code: 'USD' },
  HK: { currency: 'HKD', country: 'Hong Kong', currency_code: 'HKD' },
  CA: { currency: 'Canadian Dollar', country: 'Canada', currency_code: 'CAD' },
  JP: { currency: 'Japanese Yen', country: 'Japan', currency_code: 'JPY' },
  AF: { currency: 'Afghani', country: 'Afghanistan', currency_code: 'AFN' },
  AL: { currency: 'Lek', country: 'Albania', currency_code: 'ALL' },
  DZ: { currency: 'Algerian Dinar', country: 'Algeria', currency_code: 'DZD' },
  AI: {
    currency: 'East Caribbean Dollar',
    country: 'Anguilla',
    currency_code: 'XCD',
  },
  AG: {
    currency: 'East Caribbean Dollar',
    country: 'Antigua and Barbuda',
    currency_code: 'XCD',
  },
  DM: {
    currency: 'East Caribbean Dollar',
    country: 'Dominica',
    currency_code: 'XCD',
  },
  GD: {
    currency: 'East Caribbean Dollar',
    country: 'Grenada',
    currency_code: 'XCD',
  },
  MS: {
    currency: 'East Caribbean Dollar',
    country: 'Montserrat',
    currency_code: 'XCD',
  },
  KN: {
    currency: 'East Caribbean Dollar',
    country: 'Saint Kitts',
    currency_code: 'XCD',
  },
  LC: {
    currency: 'East Caribbean Dollar',
    country: 'Saint Lucia',
    currency_code: 'XCD',
  },
  VC: {
    currency: 'East Caribbean Dollar',
    country: 'Saint Vincent Grenadines',
    currency_code: 'XCD',
  },
  AR: { currency: 'Peso', country: 'Argentina', currency_code: 'ARS' },
  AM: { currency: 'Dram', country: 'Armenia', currency_code: 'AMD' },
  AW: {
    currency: 'Netherlands Antilles Guilder',
    country: 'Aruba',
    currency_code: 'ANG',
  },
  AN: {
    currency: 'Netherlands Antilles Guilder',
    country: 'Netherlands Antilles',
    currency_code: 'ANG',
  },
  AZ: { currency: 'Manat', country: 'Azerbaijan', currency_code: 'AZN' },
  BS: { currency: 'Bahamian Dollar', country: 'Bahamas', currency_code: 'BSD' },
  BH: { currency: 'Bahraini Dinar', country: 'Bahrain', currency_code: 'BHD' },
  BD: { currency: 'Taka', country: 'Bangladesh', currency_code: 'BDT' },
  BB: {
    currency: 'Barbadian Dollar',
    country: 'Barbados',
    currency_code: 'BBD',
  },
  BY: { currency: 'Belarus Ruble', country: 'Belarus', currency_code: 'BYR' },
  BZ: { currency: 'Belizean Dollar', country: 'Belize', currency_code: 'BZD' },
  BJ: { currency: 'CFA Franc BCEAO', country: 'Benin', currency_code: 'XOF' },
  BF: {
    currency: 'CFA Franc BCEAO',
    country: 'Burkina Faso',
    currency_code: 'XOF',
  },
  GW: {
    currency: 'CFA Franc BCEAO',
    country: 'Guinea-Bissau',
    currency_code: 'XOF',
  },
  CI: {
    currency: 'CFA Franc BCEAO',
    country: 'Ivory Coast',
    currency_code: 'XOF',
  },
  ML: { currency: 'CFA Franc BCEAO', country: 'Mali', currency_code: 'XOF' },
  NE: { currency: 'CFA Franc BCEAO', country: 'Niger', currency_code: 'XOF' },
  SN: { currency: 'CFA Franc BCEAO', country: 'Senegal', currency_code: 'XOF' },
  TG: { currency: 'CFA Franc BCEAO', country: 'Togo', currency_code: 'XOF' },
  BM: {
    currency: 'Bermudian Dollar',
    country: 'Bermuda',
    currency_code: 'BMD',
  },
  BT: { currency: 'Indian Rupee', country: 'Bhutan', currency_code: 'INR' },
  IN: { currency: 'Indian Rupee', country: 'India', currency_code: 'INR' },
  BO: { currency: 'Boliviano', country: 'Bolivia', currency_code: 'BOB' },
  BW: { currency: 'Pula', country: 'Botswana', currency_code: 'BWP' },
  BV: {
    currency: 'Norwegian Krone',
    country: 'Bouvet Island',
    currency_code: 'NOK',
  },
  NO: { currency: 'Norwegian Krone', country: 'Norway', currency_code: 'NOK' },
  SJ: {
    currency: 'Norwegian Krone',
    country: 'Svalbard and Jan Mayen Islands',
    currency_code: 'NOK',
  },
  BR: { currency: 'Brazil', country: 'Brazil', currency_code: 'BRL' },
  BN: {
    currency: 'Bruneian Dollar',
    country: 'Brunei Darussalam',
    currency_code: 'BND',
  },
  BG: { currency: 'Lev', country: 'Bulgaria', currency_code: 'BGN' },
  BI: { currency: 'Burundi Franc', country: 'Burundi', currency_code: 'BIF' },
  KH: { currency: 'Riel', country: 'Cambodia', currency_code: 'KHR' },
  CM: { currency: 'CFA Franc BEAC', country: 'Cameroon', currency_code: 'XAF' },
  CF: {
    currency: 'CFA Franc BEAC',
    country: 'Central African Republic',
    currency_code: 'XAF',
  },
  TD: { currency: 'CFA Franc BEAC', country: 'Chad', currency_code: 'XAF' },
  CG: {
    currency: 'CFA Franc BEAC',
    country: 'Congo Republic of the Democratic',
    currency_code: 'XAF',
  },
  GQ: {
    currency: 'CFA Franc BEAC',
    country: 'Equatorial Guinea',
    currency_code: 'XAF',
  },
  GA: { currency: 'CFA Franc BEAC', country: 'Gabon', currency_code: 'XAF' },
  CV: { currency: 'Escudo', country: 'Cape Verde', currency_code: 'CVE' },
  KY: {
    currency: 'Caymanian Dollar',
    country: 'Cayman Islands',
    currency_code: 'KYD',
  },
  CL: { currency: 'Chilean Peso', country: 'Chile', currency_code: 'CLP' },
  CN: { currency: 'Yuan Renminbi', country: 'China', currency_code: 'CNY' },
  CO: { currency: 'Peso', country: 'Colombia', currency_code: 'COP' },
  KM: { currency: 'Comoran Franc', country: 'Comoros', currency_code: 'KMF' },
  CD: {
    currency: 'Congolese Frank',
    country: 'Congo (Kinshasa)',
    currency_code: 'CDF',
  },
  CR: {
    currency: 'Costa Rican Colon',
    country: 'Costa Rica',
    currency_code: 'CRC',
  },
  HR: {
    currency: 'Croatian Dinar',
    country: 'Croatia (Hrvatska)',
    currency_code: 'HRK',
  },
  CU: { currency: 'Cuban Peso', country: 'Cuba', currency_code: 'CUP' },
  CY: { currency: 'Cypriot Pound', country: 'Cyprus', currency_code: 'CYP' },
  CZ: { currency: 'Koruna', country: 'Czech Republic', currency_code: 'CZK' },
  DK: { currency: 'Danish Krone', country: 'Denmark', currency_code: 'DKK' },
  FO: {
    currency: 'Danish Krone',
    country: 'Faroe Islands',
    currency_code: 'DKK',
  },
  GL: { currency: 'Danish Krone', country: 'Greenland', currency_code: 'DKK' },
  DJ: {
    currency: 'Djiboutian Franc',
    country: 'Djibouti',
    currency_code: 'DJF',
  },
  DO: {
    currency: 'Dominican Peso',
    country: 'Dominican Republic',
    currency_code: 'DOP',
  },
  TP: {
    currency: 'Indonesian Rupiah',
    country: 'East Timor',
    currency_code: 'IDR',
  },
  ID: {
    currency: 'Indonesian Rupiah',
    country: 'Indonesia',
    currency_code: 'IDR',
  },
  EC: { currency: 'Sucre', country: 'Ecuador', currency_code: 'USD' },
  EG: { currency: 'Egyptian Pound', country: 'Egypt', currency_code: 'EGP' },
  SV: {
    currency: 'Salvadoran Colon',
    country: 'El Salvador',
    currency_code: 'SVC',
  },
  ER: { currency: 'Ethiopian Birr', country: 'Eritrea', currency_code: 'ETB' },
  ET: { currency: 'Ethiopian Birr', country: 'Ethiopia', currency_code: 'ETB' },
  EE: { currency: 'Estonian Kroon', country: 'Estonia', currency_code: 'EEK' },
  FK: {
    currency: 'Falkland Pound',
    country: 'Falkland Islands (Malvinas)',
    currency_code: 'FKP',
  },
  FJ: { currency: 'Fijian Dollar', country: 'Fiji', currency_code: 'FJD' },
  PF: {
    currency: 'CFP Franc',
    country: 'French Polynesia',
    currency_code: 'XPF',
  },
  NC: { currency: 'CFP Franc', country: 'New Caledonia', currency_code: 'XPF' },
  WF: {
    currency: 'CFP Franc',
    country: 'Wallis and Futuna Islands',
    currency_code: 'XPF',
  },
  GM: { currency: 'Dalasi', country: 'Gambia', currency_code: 'GMD' },
  GE: { currency: 'Lari', country: 'Georgia', currency_code: 'GEL' },
  GI: {
    currency: 'Gibraltar Pound',
    country: 'Gibraltar',
    currency_code: 'GIP',
  },
  GT: { currency: 'Quetzal', country: 'Guatemala', currency_code: 'GTQ' },
  GN: { currency: 'Guinean Franc', country: 'Guinea', currency_code: 'GNF' },
  GY: { currency: 'Guyanaese Dollar', country: 'Guyana', currency_code: 'GYD' },
  HT: { currency: 'Gourde', country: 'Haiti', currency_code: 'HTG' },
  HN: { currency: 'Lempira', country: 'Honduras', currency_code: 'HNL' },
  HU: { currency: 'Forint', country: 'Hungary', currency_code: 'HUF' },
  IS: { currency: 'Icelandic Krona', country: 'Iceland', currency_code: 'ISK' },
  IR: {
    currency: 'Iranian Rial',
    country: 'Iran (Islamic Republic of)',
    currency_code: 'IRR',
  },
  IQ: { currency: 'Iraqi Dinar', country: 'Iraq', currency_code: 'IQD' },
  IL: { currency: 'Shekel', country: 'Israel', currency_code: 'ILS' },
  JM: { currency: 'Jamaican Dollar', country: 'Jamaica', currency_code: 'JMD' },
  JO: { currency: 'Jordanian Dinar', country: 'Jordan', currency_code: 'JOD' },
  KZ: { currency: 'Tenge', country: 'Kazakhstan', currency_code: 'KZT' },
  KE: { currency: 'Kenyan Shilling', country: 'Kenya', currency_code: 'KES' },
  KP: { currency: 'Won', country: 'Korea North', currency_code: 'KPW' },
  KR: { currency: 'Won', country: 'Korea South', currency_code: 'KRW' },
  KW: { currency: 'Kuwaiti Dinar', country: 'Kuwait', currency_code: 'KWD' },
  KG: { currency: 'Som', country: 'Kyrgyzstan', currency_code: 'KGS' },
  LA: { currency: 'Lao kip', country: 'Laos', currency_code: 'LAK' },
  LV: { currency: 'Lat', country: 'Latvia', currency_code: 'LVL' },
  LB: { currency: 'Lebanese Pound', country: 'Lebanon', currency_code: 'LBP' },
  LS: { currency: 'Loti', country: 'Lesotho', currency_code: 'LSL' },
  LR: { currency: 'Liberian Dollar', country: 'Liberia', currency_code: 'LRD' },
  LY: {
    currency: 'Libyan Dinar',
    country: 'Libyan Arab Jamahiriya',
    currency_code: 'LYD',
  },
  LI: {
    currency: 'Swiss Franc',
    country: 'Liechtenstein',
    currency_code: 'CHF',
  },
  CH: { currency: 'Swiss Franc', country: 'Switzerland', currency_code: 'CHF' },
  LT: { currency: 'Lita', country: 'Lithuania', currency_code: 'LTL' },
  MO: {
    currency: 'Macanese pataca',
    country: 'Macao S.A.R.',
    currency_code: 'MOP',
  },
  MK: { currency: 'Denar', country: 'Macedonia', currency_code: 'MKD' },
  MG: {
    currency: 'Malagasy Franc',
    country: 'Madagascar',
    currency_code: 'MGA',
  },
  MW: { currency: 'Malawian Kwacha', country: 'Malawi', currency_code: 'MWK' },
  MY: { currency: 'Ringgit', country: 'Malaysia', currency_code: 'MYR' },
  MV: { currency: 'Rufiyaa', country: 'Maldives', currency_code: 'MVR' },
  MT: { currency: 'Maltese Lira', country: 'Malta', currency_code: 'MTL' },
  MR: { currency: 'Ouguiya', country: 'Mauritania', currency_code: 'MRO' },
  MU: {
    currency: 'Mauritian Rupee',
    country: 'Mauritius',
    currency_code: 'MUR',
  },
  MX: { currency: 'Peso', country: 'Mexico', currency_code: 'MXN' },
  MD: { currency: 'Leu', country: 'Moldova Republic of', currency_code: 'MDL' },
  MN: { currency: 'Tugrik', country: 'Mongolia', currency_code: 'MNT' },
  MA: { currency: 'Dirham', country: 'Morocco', currency_code: 'MAD' },
  EH: { currency: 'Dirham', country: 'Western Sahara', currency_code: 'MAD' },
  MZ: { currency: 'Metical', country: 'Mozambique', currency_code: 'MZN' },
  MM: { currency: 'Kyat', country: 'Myanmar', currency_code: 'MMK' },
  NA: { currency: 'Dollar', country: 'Namibia', currency_code: 'NAD' },
  NP: { currency: 'Nepalese Rupee', country: 'Nepal', currency_code: 'NPR' },
  NI: { currency: 'Cordoba Oro', country: 'Nicaragua', currency_code: 'NIO' },
  NG: { currency: 'Naira', country: 'Nigeria', currency_code: 'NGN' },
  OM: { currency: 'Sul Rial', country: 'Oman', currency_code: 'OMR' },
  PK: { currency: 'Rupee', country: 'Pakistan', currency_code: 'PKR' },
  PA: { currency: 'Balboa', country: 'Panama', currency_code: 'PAB' },
  PG: { currency: 'Kina', country: 'Papua New Guinea', currency_code: 'PGK' },
  PY: { currency: 'Guarani', country: 'Paraguay', currency_code: 'PYG' },
  PE: { currency: 'Nuevo Sol', country: 'Peru', currency_code: 'PEN' },
  PH: { currency: 'Peso', country: 'Philippines', currency_code: 'PHP' },
  PL: { currency: 'Zloty', country: 'Poland', currency_code: 'PLN' },
  QA: { currency: 'Rial', country: 'Qatar', currency_code: 'QAR' },
  RO: { currency: 'Leu', country: 'Romania', currency_code: 'RON' },
  RU: {
    currency: 'Ruble',
    country: 'Russian Federation',
    currency_code: 'RUB',
  },
  RW: { currency: 'Rwanda Franc', country: 'Rwanda', currency_code: 'RWF' },
  ST: {
    currency: 'Dobra',
    country: 'Sao Tome and Principe',
    currency_code: 'STD',
  },
  SA: { currency: 'Riyal', country: 'Saudi Arabia', currency_code: 'SAR' },
  SC: { currency: 'Rupee', country: 'Seychelles', currency_code: 'SCR' },
  SL: { currency: 'Leone', country: 'Sierra Leone', currency_code: 'SLL' },
  SG: { currency: 'Dollar', country: 'Singapore', currency_code: 'SGD' },
  SK: {
    currency: 'Koruna',
    country: 'Slovakia (Slovak Republic)',
    currency_code: 'SKK',
  },
  SB: {
    currency: 'Solomon Islands Dollar',
    country: 'Solomon Islands',
    currency_code: 'SBD',
  },
  SO: { currency: 'Shilling', country: 'Somalia', currency_code: 'SOS' },
  ZA: { currency: 'Rand', country: 'South Africa', currency_code: 'ZAR' },
  LK: { currency: 'Rupee', country: 'Sri Lanka', currency_code: 'LKR' },
  SD: { currency: 'Dinar', country: 'Sudan', currency_code: 'SDG' },
  SR: {
    currency: 'Surinamese Guilder',
    country: 'Suriname',
    currency_code: 'SRD',
  },
  SZ: { currency: 'Lilangeni', country: 'Swaziland', currency_code: 'SZL' },
  SE: { currency: 'Krona', country: 'Sweden', currency_code: 'SEK' },
  SY: {
    currency: 'Syrian Pound',
    country: 'Syrian Arab Republic',
    currency_code: 'SYP',
  },
  TW: { currency: 'Dollar', country: 'Taiwan', currency_code: 'TWD' },
  TJ: {
    currency: 'Tajikistan Ruble',
    country: 'Tajikistan',
    currency_code: 'TJS',
  },
  TZ: { currency: 'Shilling', country: 'Tanzania', currency_code: 'TZS' },
  TH: { currency: 'Baht', country: 'Thailand', currency_code: 'THB' },
  TO: { currency: 'PaÕanga', country: 'Tonga', currency_code: 'TOP' },
  TT: {
    currency: 'Trinidad and Tobago Dollar',
    country: 'Trinidad and Tobago',
    currency_code: 'TTD',
  },
  TN: { currency: 'Tunisian Dinar', country: 'Tunisia', currency_code: 'TND' },
  TR: { currency: 'Lira', country: 'Turkey', currency_code: 'TRY' },
  TM: { currency: 'Manat', country: 'Turkmenistan', currency_code: 'TMT' },
  UG: { currency: 'Shilling', country: 'Uganda', currency_code: 'UGX' },
  UA: { currency: 'Hryvnia', country: 'Ukraine', currency_code: 'UAH' },
  AE: {
    currency: 'Dirham',
    country: 'United Arab Emirates',
    currency_code: 'AED',
  },
  UY: { currency: 'Peso', country: 'Uruguay', currency_code: 'UYU' },
  UZ: { currency: 'Som', country: 'Uzbekistan', currency_code: 'UZS' },
  VU: { currency: 'Vatu', country: 'Vanuatu', currency_code: 'VUV' },
  VE: { currency: 'Bolivar', country: 'Venezuela', currency_code: 'VEF' },
  VN: { currency: 'Dong', country: 'Vietnam', currency_code: 'VND' },
  YE: { currency: 'Rial', country: 'Yemen', currency_code: 'YER' },
  ZM: { currency: 'Kwacha', country: 'Zambia', currency_code: 'ZMK' },
  ZW: {
    currency: 'Zimbabwe Dollar',
    country: 'Zimbabwe',
    currency_code: 'ZWD',
  },
  AX: { currency: 'Euro', country: 'Aland Islands', currency_code: 'EUR' },
  AO: { currency: 'Angolan kwanza', country: 'Angola', currency_code: 'AOA' },
  AQ: {
    currency: 'Antarctican dollar',
    country: 'Antarctica',
    currency_code: 'AQD',
  },
  BA: {
    currency: 'Bosnia and Herzegovina convertible mark',
    country: 'Bosnia and Herzegovina',
    currency_code: 'BAM',
  },
  GH: { currency: 'Ghana cedi', country: 'Ghana', currency_code: 'GHS' },
  GG: { currency: 'Guernsey pound', country: 'Guernsey', currency_code: 'GGP' },
  IM: { currency: 'Manx pound', country: 'Isle of Man', currency_code: 'GBP' },
  ME: { currency: 'Euro', country: 'Montenegro', currency_code: 'EUR' },
  PS: {
    currency: 'Jordanian dinar',
    country: 'Palestinian Territory',
    currency_code: 'JOD',
  },
  BL: { currency: 'Euro', country: 'Saint Barthelemy', currency_code: 'EUR' },
  SH: {
    currency: 'Saint Helena pound',
    country: 'Saint Helena',
    currency_code: 'GBP',
  },
  MF: {
    currency: 'Netherlands Antillean guilder',
    country: 'Saint Martin (French part)',
    currency_code: 'ANG',
  },
  PM: {
    currency: 'Euro',
    country: 'Saint Pierre and Miquelon',
    currency_code: 'EUR',
  },
  RS: { currency: 'Serbian dinar', country: 'Serbia', currency_code: 'RSD' },
  USAF: {
    currency: 'US Dollar',
    country: 'US Armed Forces',
    currency_code: 'USD',
  },
}
