import 'promise-polyfill'
import '../MyFontsWebfontsKit.css'
import '../GothamFonts.css'
import '../GTSuperFonts.css'
import { ChakraProvider, CSSReset } from '@chakra-ui/core'
import {
  CheckoutProvider,
  StripeElementLocale,
  StripeElementProvider,
  logger,
  COUNTRY_OVERRIDE_KEY,
} from '@pangaea-holdings/pangaea-checkout'
import * as Sentry from '@sentry/node'
import { createWrapper, MakeStore, Context } from 'next-redux-wrapper'
import { AppProps, NextWebVitalsMetric } from 'next/app'
import { AppContextType } from 'next/dist/shared/lib/utils'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import React, { useEffect, useRef } from 'react'
import { TinaProvider, TinaCMS } from 'tinacms'

import '../../public/nprogress.css'
import 'react-datepicker/dist/react-datepicker.css'
import config, { updateConfig, exportConfig } from '../core/config'
import { updateFeatureFlags } from '../core/featureFlags'
import { theme as glamneticTheme } from '../design/themes/glamnetic-theme'
import { theme as zitstickaNewTheme } from '../design/themes/zitsticka-new-theme'
import { theme as zitstickaTheme } from '../design/themes/zitsticka-theme'
import { usePageProgressBar } from '../hooks/usePageProgressBar'
import { useSelectedTheme, getBrandTheme } from '../hooks/useSelectedTheme'
import { useAccountAuth } from '../modules/account/hooks/useAccountAuth'
import accountRoutes from '../modules/account/routes'
import AnalyticsScriptTag from '../modules/analytics/components/AnalyticsScriptTag'
import ManualAnalyticsTags from '../modules/analytics/components/ManualAnalyticsTags'
import AnalyticsProvider from '../modules/analytics/context/provider'
import { TENANT_KEYS, getBrandPublicSettings } from '../modules/api/index'
import CurrencyDetector from '../modules/brand/components/CurrencyDetector'
import { useBrandListener } from '../modules/brand/hooks/useBrandListener'
import lifecycle from '../modules/brand/listeners/lifecycle'
import BrandBar from '../modules/brandBar/components/BrandBar'
import { UpdateCartCurrencies } from '../modules/cartv2/components/Cart/updateCurrencyList'
import { selectIsCartDrawerOpen } from '../modules/cartv2/selectors'
import {
  fetchTranslations,
  initializeLanguage,
  detectLanguage,
  LanguageResources,
  initializeFallbackLanguage,
} from '../modules/i18n/actions'
import OptimizelyScriptTag from '../modules/optimizely/components/OptimizelyScriptTag'
import { ErrorBoundary } from '../modules/shared/components/ErrorBoundary'
import { CopyrightYearProvider } from '../modules/shared/context/CopyrightYearContext'
import { AppState } from '../redux/rootReducer'
import { createStore, useTypedDispatch, useTypedSelector } from '../redux/store'
import initStripe from '../stripe'
import { isServerRequest } from '../utils/nextjs'

import { SETTINGS } from 'core/config/config'
import { FeatureFlagSettingsType } from 'core/featureFlags/featureFlags'
import { trackWebVitals } from 'modules/analytics/functions/track'
import { setCurrentBrand } from 'modules/brand/actions'
import CountriesModal from 'modules/brand/components/CountriesModal'
import OsanoScriptTag from 'modules/brand/components/OsanoScriptTag'
import BrandContextProvider from 'modules/brand/context'
import { findBrandByPath } from 'modules/brand/functions'
import { useBrandedRoutes } from 'modules/brand/hooks/useBrandedRoutes'
import CartDrawer from 'modules/cartv2/components/Cart/CartDrawer'
import {
  getServerSideCountry,
  getServerSideCurrency,
} from 'modules/checkout/functions'
import { checkoutRoutes } from 'modules/checkout/routes'
import { getCmsGlobalData } from 'modules/cms/api'
import GlobalCMSContextProvider from 'modules/cms/context'
import { withRetry } from 'utils/functions'
import { JsonObject } from 'utils/types'

const AccountSystemProvider = dynamic(() =>
  import('@pangaea-holdings/pangaea-account').then(
    (mod) => mod.AccountSystemProvider
  )
)

const AuthProvider = dynamic(() =>
  import('@pangaea-holdings/pangaea-auth').then(
    (mod) => mod.AuthProvider
  )
)

if (config('SENTRY_DSN')) {
  Sentry.init({
    release: process.env.COMMIT_SHA,
    enabled: true,
    environment: config('APP_ENVIRONMENT'),
    dsn: config('SENTRY_DSN'),
  })
}
// first load
if (process.browser) {
  lifecycle.handleFirstLoad()
}

interface PangaeaMallAppProps extends AppProps {
  lang: string
  currency: string
  translations: LanguageResources
  brand?: string
  settings?: Partial<SETTINGS>
  featureFlagsSettings?: Partial<FeatureFlagSettingsType>
  brandTheme: typeof glamneticTheme | typeof zitstickaTheme
  globalCmsData: JsonObject
  country?: string
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
  if (typeof window === 'undefined') {
    return
  }
  logger.info('performance-metrics', {
    ...metric,
    url: window.location.href,
  })
  trackWebVitals(metric, window.location.href)
}

export function PangaeaMallApp({
  lang,
  currency,
  translations,
  Component,
  pageProps,
  brand,
  settings,
  featureFlagsSettings,
  brandTheme,
  globalCmsData,
  country,
  ...restProps
}: PangaeaMallAppProps) {
  usePageProgressBar()
  useTypedDispatch()(setCurrentBrand(brand))
  useBrandListener()
  const isCartDrawerOpen = useTypedSelector(selectIsCartDrawerOpen())

  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined' && country) {
      ;(window as unknown as any)[COUNTRY_OVERRIDE_KEY] = country
    }
  }, [country])

  if (settings) {
    updateConfig(settings)
  }

  if (featureFlagsSettings) {
    updateFeatureFlags(featureFlagsSettings)
  }

  // read account logged in status from cookie
  useAccountAuth(brand as keyof typeof TENANT_KEYS)
  // Pull out the error to report to sentry
  // Typscript types are mssed up, see: https://github.com/vercel/next.js/issues/8592
  const err = (restProps as any).err
  const theme = useSelectedTheme()

  // load translations the first render
  const i18nLoaded = useRef<boolean>(false)
  if (!i18nLoaded.current) {
    Object.keys(translations).length !== 0
      ? initializeLanguage(lang, translations)
      : initializeFallbackLanguage(lang)

    i18nLoaded.current = true
  }
  const copyRightYear: number = new Date().getFullYear()
  const checkoutBrandedRoutes = useBrandedRoutes(checkoutRoutes)
  const accountBrandedRoutes = useBrandedRoutes(accountRoutes)
  const cms = new TinaCMS({
    sidebar: true,
  })

  const stripe = initStripe()

  const updatedSettings = exportConfig()

  return (
    <StripeElementProvider stripe={stripe} locale={lang as StripeElementLocale}>
      <ChakraProvider theme={brandTheme ?? theme}>
        <CopyrightYearProvider value={copyRightYear}>
          <AnalyticsProvider>
            <CheckoutProvider
              currency={currency}
              checkoutRoutes={checkoutBrandedRoutes}
              settings={updatedSettings}
              shouldUseThemeVariants={true}
            >
              <AuthProvider settings={updatedSettings}>
                <AccountSystemProvider
                  accountSystemRoutes={accountBrandedRoutes}
                  shouldUseThemeVariants={true}
                  settings={updatedSettings}
                >
                  <UpdateCartCurrencies />
                  <CSSReset />
                  <Head>
                    <link rel="shortcut icon" href="/favicon.png" />
                  </Head>
                  {/* Osano should be loaded before other external scripts */}
                  <OsanoScriptTag />
                  <ManualAnalyticsTags />
                  <OptimizelyScriptTag />
                  {process.browser && <AnalyticsScriptTag />}

                  <TinaProvider cms={cms}>
                    <ErrorBoundary>
                      <CurrencyDetector />
                      <GlobalCMSContextProvider globalCMSValues={globalCmsData}>
                        <BrandContextProvider>
                          <BrandBar />
                          <CountriesModal />
                          <Component {...pageProps} err={err} />
                          <CartDrawer isOpen={isCartDrawerOpen} />
                        </BrandContextProvider>
                      </GlobalCMSContextProvider>
                    </ErrorBoundary>
                  </TinaProvider>
                </AccountSystemProvider>
              </AuthProvider>
            </CheckoutProvider>
          </AnalyticsProvider>
        </CopyrightYearProvider>
      </ChakraProvider>
    </StripeElementProvider>
  )
}

PangaeaMallApp.getInitialProps = async ({ ctx, router }: AppContextType) => {
  const brand = findBrandByPath(router.asPath)
  const retryBrandPublicSettings = withRetry(getBrandPublicSettings)
  let settings: Partial<SETTINGS> | undefined = undefined
  let featureFlagsSettings: Partial<FeatureFlagSettingsType> | undefined =
    undefined
  let brandTheme:
    | typeof zitstickaTheme
    | typeof glamneticTheme
    | typeof zitstickaNewTheme
    | undefined = undefined
  let globalCmsData: JsonObject = {}

  if (brand) {
    const { configSettings, featureFlags } = await retryBrandPublicSettings(
      brand
    )
    settings = configSettings
    featureFlagsSettings = featureFlags

    // Make sure the updated settings are available server side.
    updateConfig(settings)

    brandTheme = getBrandTheme(brand)
    globalCmsData = await getCmsGlobalData(brand)
  }

  if (!isServerRequest(ctx) || process.browser) {
    return { brand, settings, featureFlagsSettings }
  }

  const country = getServerSideCountry(ctx, true)
  const lang = detectLanguage(ctx)
  const currency = getServerSideCurrency(ctx)

  let translations = {}
  try {
    const now = Date.now()
    if (lang !== 'en') {
      // only fetch translations on the first request, subsequent requests
      // will have translations in memory
      translations = await fetchTranslations(lang)
      console.log('fetching translations took: ' + (Date.now() - now))
    }
  } catch (e) {
    // swallow for now because pre-rendering doesnt like this
  }

  return {
    translations,
    lang,
    currency,
    brand,
    settings,
    featureFlagsSettings,
    brandTheme,
    globalCmsData,
    country,
  }
}

const makeStore: MakeStore<AppState> = (context: Context) => createStore()

export default createWrapper(makeStore).withRedux(PangaeaMallApp)
