const defaultProps = {
  variant: 'primary',
  size: 'md',
}

export const Button = {
  defaultProps,
  baseStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none',
    lineHeight: 'tight',
    pt: '2px',
    fontFamily: 'regular',
    px: 4,
  },

  sizes: {
    xl: {
      minHeight: ['heightXlMobile', 'heightXlTablet', 'heightXl'],
      fontSize: ['buttonLgMobile', null, 'buttonLg'],
    },

    lg: {
      minHeight: ['heightLgMobile', 'heightLgTablet', 'heightLg'],
      fontSize: ['buttonLgMobile', null, 'buttonLg'],
    },

    md: {
      minHeight: ['heightMdMobile', 'heightMdTablet', 'heightMd'],
      fontSize: ['buttonMdMobile', null, 'buttonMd'],
    },
    sm: {
      minHeight: ['heightSmMobile', 'heightSmTablet', 'heightSm'],
      fontSize: ['buttonSmMobile', null, 'buttonSm'],
    },
  },

  colorSchemes: {
    sage: {
      bg: 'sage',
      color: 'whiteSands',
      _hover: {
        bg: 'nightGreen',
      },
      _disabled: {
        bg: 'disabled',
      },
    },
  },
  variants: {
    primary: {
      width: ['100%', null, 'auto'],
      minWidth: [null, null, '190px'],
      whiteSpace: 'normal',
      bg: 'btnPrimaryBg',
      color: 'white',
      borderRadius: '4px',
      _hover: {
        bg: 'nightGreen',
      },
      _disabled: {
        border: 'disabled',
        color: 'white',
        bg: 'disabled',
        cursor: 'default',
      },
    },
    primaryInverted: {
      width: ['100%', null, 'auto'],
      minWidth: [null, null, '190px'],
      bg: 'white',
      color: 'btnPrimaryBg',
      borderRadius: '4px',
      _hover: {
        bg: 'foam',
      },
      _disabled: {
        border: 'disabled',
        color: 'white',
        bg: 'disabled',
        cursor: 'default',
      },
    },
    secondary: {
      width: '100%',
      bg: 'grapeFruit',
      color: 'offwite',
      border: 'grapeFruit',
      _hover: {
        cursor: 'pointer',
        bg: 'midnightBlue',
        border: 'midnightBlue',
      },
    },
    red: {
      width: ['100%', null, 'auto'],
      minWidth: [null, null, '190px'],
      bg: 'grapeFruit',
      color: 'sandstone',
      _hover: {
        bg: 'moss',
      },
      _disabled: {
        bg: 'disabled',
      },
    },

    outline: {
      width: ['100%', null, 'auto'],
      minWidth: [null, null, '190px'],
      whiteSpace: 'normal',
      bg: 'transparent',
      border: 'nightGreen',
      color: 'buttonText',
      _hover: {
        bg: 'nightGreen',
        color: 'whiteSands',
      },
      _disabled: {
        border: 'disabled',
        color: 'disabled',
        bg: 'transparent',
        cursor: 'default',
      },
    },

    back: {
      width: ['100%', null, 'auto'],
      minWidth: ['100%', null, '190px'],
      bg: 'transparent',
      border: 'nightGreen',
      color: 'buttonText',
      _hover: {
        bg: 'nightGreen',
        color: 'whiteSands',
      },
      _disabled: {
        border: 'disabled',
        color: 'disabled',
        bg: 'transparent',
      },
    },

    'outline-white': {
      width: ['100%', null, 'auto'],
      minWidth: [null, null, '190px'],
      bg: 'transparent',
      border: 'whiteSands',
      color: 'whiteSands',
      _hover: {
        color: 'moss',
        border: 'moss',
        bg: 'whiteSands',
      },
      _disabled: {
        border: 'disabled',
        color: 'disabled',
        bg: 'transparent',
      },
    },
    'outline-white-no-hover': {
      width: ['100%', null, 'auto'],
      minWidth: [null, null, '190px'],
      bg: 'transparent',
      border: 'whiteSands',
      color: 'whiteSands',
      _disabled: {
        border: 'disabled',
        color: 'disabled',
        bg: 'transparent',
      },
    },
    'outline-grey': {
      width: '100%',
      py: 3,
      px: 6,
      bg: 'transparent',
      color: 'sage',
      border: 'seastone',
      _hover: {
        border: 'nightGreen',
        color: 'buttonText',
      },
      _disabled: {
        border: 'disabled',
        color: 'disabled',
        bg: 'transparent',
      },
    },
    'outline-grey-no-hover': {
      width: '100%',
      py: 3,
      px: 6,
      bg: 'transparent',
      color: 'sage',
      border: 'seastone',
      _disabled: {
        border: 'disabled',
        color: 'disabled',
        bg: 'transparent',
      },
    },
    blank: {
      display: 'inline-block',
      border: 'none',
      lineHeight: 'inherit',
      minWidth: 'auto',
      minHeight: 'auto',
      width: 'auto',
      height: 'auto',
      // width: ['auto', 'auto', 'auto'],
      fontWeight: 'inherit',
      fontFamily: 'inherit',
      pt: 0,
      px: 0,
      p: 0,
      background: 'none',
      _disabled: {
        color: 'disabled',
        cursor: 'default',
      },
    },
    rust: {
      width: ['100%', null, 'auto'],
      minWidth: ['90px', '150px', '190px'],
      fontSize: ['sm', 'md'],
      py: [1, 3],
      textTransform: 'uppercase',
      minHeight: '1.8rem',
      bg: 'rust',
      color: 'sandstone',
      _hover: {
        bg: 'rustHover',
        color: 'tertiaryTextColor',
      },
      _disabled: {
        bg: 'disabled',
      },
    },
    heroCta: {
      minWidth: [null, null, '190px'],
      width: ['auto', '100%'],
      maxWidth: ['9.875rem', '17rem'],
      mt: [6, null, 10],
      px: [8, 'auto'],
      py: [4, 'auto'],
      bg: 'btnPrimaryBg',
      color: 'white',
      borderRadius: '4px',
      _hover: {
        bg: 'nightGreen',
      },
      _disabled: {
        border: 'disabled',
        color: 'white',
        bg: 'disabled',
        cursor: 'default',
      },
    },
  },
}
