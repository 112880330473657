import { Product, ProductContent } from '@pangaea-holdings/pangaea-checkout'

import { ProductsActionTypes } from './actionTypes'

export type ProductsReducerState = {
  all: {
    [key: string]: Product[]
  }
  single: {
    [key: string]: Product[]
  }
  details: { [key: string]: ProductContent }
}

const initialState: ProductsReducerState = {
  all: {},
  single: {},
  details: {},
}

export function productsReducer(
  state = initialState,
  action: ProductsActionTypes
): ProductsReducerState {
  switch (action.type) {
    case 'PRODUCTS_LOAD_ALL':
      return {
        ...state,
        all: {
          ...state.all,
          [getProductsKey(
            action.payload.brand,
            action.payload.currency,
            action.payload.country
          )]: action.payload.products,
        },
      }
    case 'PRODUCTS_LOAD_MULTIPLE':
      return loadMultipleProducts(
        state,
        action.payload.currency,
        action.payload.products,
        action.payload.brand,
        action.payload.country
      )

    case 'PRODUCTS_LOAD_SINGLE':
      return loadSingleProduct(
        state,
        action.payload.currency,
        action.payload.product,
        action.payload.brand,
        action.payload.country
      )

    case 'PRODUCTS_LOAD_SINGLE_DETAILS':
      return {
        ...state,
        details: {
          ...state.details,
          [getProductDetailsKey(action.payload.brand, action.payload.idOrSlug)]:
            action.payload.productContent,
        },
      }

    default:
      return state
  }
}

function loadSingleProduct(
  state: ProductsReducerState,
  currency: string,
  product: Product,
  brand: string,
  country?: string
): ProductsReducerState {
  const { single } = state
  const key = getProductsKey(brand, currency, country)
  single[key] = single[key] || []
  const ind = single[key].findIndex((a) => a.id === product.id)
  if (ind > -1) {
    single[key][ind] = product
  } else {
    single[key].push(product)
  }

  return {
    ...state,
    single,
  }
}

function loadMultipleProducts(
  state: ProductsReducerState,
  currency: string,
  products: Product[],
  brand: string,
  country?: string
): ProductsReducerState {
  return products.reduce<ProductsReducerState>((carry, item) => {
    return loadSingleProduct(carry, currency, item, brand, country)
  }, state)
}

export function getProductsKey(
  brand: string,
  currency: string,
  country?: string
): string {
  return (
    `${brand}|${currency.toUpperCase()}` +
    `${country ? `|${country.toUpperCase()}` : ''}`
  )
}

export function getProductDetailsKey(
  brand: string,
  idOrSlug: string | number
): string {
  return `${brand}|${idOrSlug}`
}
