import {chakra} from '@chakra-ui/core'
import { ThemeContext } from '@emotion/core'
import PropTypes from 'prop-types'
import { useContext, forwardRef } from 'react'

const Svg = chakra('svg', {
  baseStyle: {
    flexShrink: 0,
  }
})
// const Svg = styled(Box)`
//   flex-shrink: 0;
//   backface-visibility: hidden;
// `

const Icon = forwardRef(
  (
    {
      width = '1em',
      height = '1em',

      name,
      color = 'currentColor',
      role = 'presentation',
      focusable = false,
      ...rest
    },
    ref
  ) => {
    const { icons: iconPaths } = useContext(ThemeContext)

    // Fallback in case you pass the wrong name
    const iconFallback = iconPaths['question-outline']

    const path =
      iconPaths[name] == null ? iconFallback.path : iconPaths[name].path

    const viewBox =
      (iconPaths[name] == null
        ? iconFallback.viewBox
        : iconPaths[name].viewBox) || '0 0 24 24'

    return (
      <Svg
        ref={ref}
        as="svg"
        width={width}
        height={height}
        color={color}
        display="inline-block"
        verticalAlign="middle"
        viewBox={viewBox}
        focusable={focusable}
        role={role}
        {...rest}
      >
        {path}
      </Svg>
    )
  }
)

Icon.displayName = 'Icon'

Icon.propTypes = {
  name: PropTypes.string.isRequired,
}

export default Icon
