// The dynamic Feature Flag Settings controlled by Admin Tool( here we set the default values)
export const FEATURE_FLAG_SETTINGS = {
  DISABLE_APPLE_PAY: false,
  ENABLE_POST_PURCHASE_OFFERS: false,
  ENABLE_BREXIT_VAT_UPDATE: true,
  ENABLE_WARRANTY: false,
  SHOW_GOVX_BUTTON: true,
  SHOW_SUBSCRIPTION_NOTICE: true,
  ALWAYS_SHOW_DISCOUNT_FORM_IN_MOBILE: true,
  SHOW_SHIPPING_MODAL_WITH_SUGGESTION_ONLY: true,
  SHOW_ORDER_TOTAL_BEFORE_DISCOUNT: true,
  DISABLE_EXPRESS_FOR_PO_BOX: false,
  SHOW_SOCIAL_MEDIA_LINKS: false,
  ENABLE_ADYEN: false,
  ENABLE_CASH_ON_DELIVERY: false,
  ENABLE_AFFIRM: false,
  ENABLE_AFTERPAY: false,
  ENABLE_APPLEPAY: false,
  ENABLE_PAYPAL: false,
  ENABLE_KLARNA: false,
  ENABLE_PMALL_EMAIL_CHECKOUT_OPT: true,
} as const

export type FeatureFlagSettingsType = typeof FEATURE_FLAG_SETTINGS
