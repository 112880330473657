import {Box, useTheme} from '@chakra-ui/core'
import React from 'react'
import {useTranslation} from 'react-i18next'
import ReactMarkdown from 'react-markdown'

import config from '../../../core/config'
import useWindowDimensions from '../../../design/hooks/useWindowDimensions'
import {useTypedSelector} from '../../../redux/store'
import {selectBrand} from '../../brand/selectors'
import {WithGlobalCMSOption} from '../../cms/hoc/WithGlobalCMSOption'
import useCMSTranslation from '../../cms/hooks/useCMSTranslation'
import {PmallBlackIcon} from '../../shared/components/Icons/PmallBlack'

import TextField from 'modules/cms/fields/Text'

interface CurrentBrandBarProps {
  cmsValues?: {
    showBanner?: boolean
    bgColor?: string
    textColor?: string
    mobileText?: string
  }
}

const BrandBar: React.FC<CurrentBrandBarProps> = (props) => {
  const {t} = useTranslation()
  const brand = useTypedSelector(selectBrand())
  const brandName = config('BRAND_NAME')
  const mobileOnlyText = props?.cmsValues?.mobileText || ''

  const theme = useTheme()
  const dimensions = useWindowDimensions()
  const cmsT = useCMSTranslation()

  const defaultBrandText = t(
    'common:current-brand-message',
    'You are shopping {{brandName}} on',
    {brandName}
  )

  const availableVariables = {brandName, defaultBrandText}

  const isMobile = (): boolean => {
    return (
      dimensions.innerWidth <= parseInt(theme.breakpoints[1].split('px')[0])
    )
  }

  if (!brand || !isMobile()) {
    return null
  }

  return (
    <Box
      bg={props.cmsValues?.bgColor}
      color={props.cmsValues?.textColor}
      alignItems="center"
      justifyContent="center"
      p={3}
      display={props.cmsValues?.showBanner ? 'flex' : 'none'}
    >
      <ReactMarkdown>
        {mobileOnlyText && cmsT(mobileOnlyText, availableVariables)}
      </ReactMarkdown>
      <PmallBlackIcon
        width={['4.375rem', '4.375rem']}
        height={['0.75rem', '0.75rem']}
        ml={3}
        display="block"
        alt="PMall"
      />
    </Box>
  )
}

export default WithGlobalCMSOption(BrandBar, 'Mobile only Banner', {
  label: 'Mobile only Banner',
  fields: [
    { name: 'showBanner', label: 'Show Banner', component: 'toggle' },
    { name: 'bgColor', label: 'Background Color', component: 'color' },
    { name: 'textColor', label: 'Text Color', component: 'color' },
    {
      name: 'mobileText',
      label: 'Mobile only banner text',
      component: TextField,
      availableVariables: {
        brandName: 'Current Brand Name',
        defaultBrandText: 'You are shopping {Current Brand Name} on',
      },
    },
  ],
})
