import productRoutes from '../products/routes'
import staticRoutes from '../static/routes'

import buildRoutes, { routeFromUrl } from 'modules/shared/buildRoutes'

export const checkoutRoutes = buildRoutes('checkout', {
  checkout: (params?: { hash: string; coupon?: string }) => {
    if (!params) {
      return '[checkoutId]'
    }

    const { hash, coupon } = params

    if (!coupon) {
      return `${hash}`
    }

    return `${hash}?coupon_override=${coupon}`
  },

  checkoutConfirmation: routeFromUrl<{ hash: string }>('[hash]/confirmation'),
  productsPage: () => productRoutes.products({ scroll: false }),
  homePage: () => productRoutes.products({ scroll: false }),
  pageNotFound: () => '/404',
  privacyPolicy: () => staticRoutes.privacyPolicy(),
  returnPolicy: () => staticRoutes.returnPolicy(),
  termsOfService: () => staticRoutes.termsOfService(),
  subscriptions: () => staticRoutes.subscriptions(),
})
