const defaultProps = {
  variant: 'primary',
  size: 'md',
}

export const Button = {
  defaultProps,
  baseStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none',
    lineHeight: 'tight',
    pt: '2px',
    fontFamily: 'regular',
    px: 4,
  },

  fontSizes: {
    buttonLgMobile: '.75rem',
    buttonMdMobile: '.6875rem',
    buttonSmMobile: '.75rem',

    buttonLg: '1.125rem',
    buttonMd: '.82rem',
    buttonSm: '.75rem',
  },

  sizes: {
    xl: {
      minHeight: ['heightXlMobile', 'heightXlTablet', 'heightXl'],
      fontSize: ['buttonLgMobile', null, 'buttonLg'],
    },

    lg: {
      minHeight: ['heightLgMobile', 'heightLgTablet', 'heightLg'],
      fontSize: ['buttonLgMobile', null, 'buttonLg'],
    },

    md: {
      minHeight: ['heightMdMobile', 'heightMdTablet', 'heightMd'],
      fontSize: ['buttonMdMobile', null, 'buttonMd'],
    },
    sm: {
      minHeight: ['heightSmMobile', 'heightSmTablet', 'heightSm'],
      fontSize: ['buttonSmMobile', null, 'buttonSm'],
    },
  },

  colorSchemes: {
    sage: {
      bg: 'sage',
      color: 'whiteSands',
      _hover: {
        bg: 'nightGreen',
      },
      _disabled: {
        bg: 'disabled',
      },
    },
  },
  variants: {
    primary: {
      width: ['100%', null, 'auto'],
      whiteSpace: 'normal',
      bg: 'btnPrimaryBg',
      color: 'white',
      borderRadius: '4px',
      _hover: {
        bg: 'hoverBg',
        boxShadow: 'primButtonShadow',
      },
      _disabled: {
        border: 'disabled',
        color: 'white',
        bg: 'disabled',
        cursor: 'default',
      },
    },
    primaryInverted: {
      width: ['100%', null, 'auto'],
      minWidth: [null, null, '190px'],
      bg: 'white',
      color: 'btnPrimaryBg',
      borderRadius: '4px',
      _hover: {
        bg: 'foam',
      },
      _disabled: {
        border: 'disabled',
        color: 'white',
        bg: 'disabled',
        cursor: 'default',
      },
    },
    secondary: {
      h: ['2.875rem', '3.125rem'],
      borderRadius: '1.7rem',
      boxShadow: 'lg',
      textTransform: 'uppercase',
      fontSize: 'md',
      fontFamily: 'bold',
      fontWeight: 'bold',
      letterSpacing: '0.15rem',
      color: 'white',
      width: '100%',
      _hover: {
        cursor: 'pointer',
      },
    },
    red: {
      width: ['100%', null, 'auto'],
      minWidth: [null, null, '190px'],
      bg: 'grapeFruit',
      color: 'sandstone',
      _hover: {
        bg: 'moss',
      },
      _disabled: {
        bg: 'disabled',
      },
    },

    outline: {
      width: ['100%', null, 'auto'],
      minWidth: [null, null, '190px'],
      whiteSpace: 'normal',
      bg: 'transparent',
      border: 'nightGreen',
      color: 'buttonText',
      _hover: {
        bg: 'nightGreen',
        color: 'whiteSands',
      },
      _disabled: {
        border: 'disabled',
        color: 'disabled',
        bg: 'transparent',
        cursor: 'default',
      },
    },

    back: {
      width: ['100%', null, 'auto'],
      minWidth: ['100%', null, '190px'],
      bg: 'transparent',
      border: 'nightGreen',
      color: 'buttonText',
      _hover: {
        bg: 'nightGreen',
        color: 'whiteSands',
      },
      _disabled: {
        border: 'disabled',
        color: 'disabled',
        bg: 'transparent',
      },
    },

    'outline-white': {
      width: ['100%', null, 'auto'],
      minWidth: [null, null, '190px'],
      bg: 'transparent',
      border: 'whiteSands',
      color: 'whiteSands',
      _hover: {
        color: 'moss',
        border: 'moss',
        bg: 'whiteSands',
      },
      _disabled: {
        border: 'disabled',
        color: 'disabled',
        bg: 'transparent',
      },
    },
    'outline-white-no-hover': {
      width: ['100%', null, 'auto'],
      minWidth: [null, null, '190px'],
      bg: 'transparent',
      border: 'whiteSands',
      color: 'whiteSands',
      _disabled: {
        border: 'disabled',
        color: 'disabled',
        bg: 'transparent',
      },
    },
    'outline-grey': {
      width: '100%',
      py: 3,
      px: 6,
      bg: 'transparent',
      color: 'sage',
      border: 'seastone',
      _hover: {
        border: 'nightGreen',
        color: 'buttonText',
      },
      _disabled: {
        border: 'disabled',
        color: 'disabled',
        bg: 'transparent',
      },
    },
    'outline-grey-no-hover': {
      width: '100%',
      py: 3,
      px: 6,
      bg: 'transparent',
      color: 'sage',
      border: 'seastone',
      _disabled: {
        border: 'disabled',
        color: 'disabled',
        bg: 'transparent',
      },
    },
    blank: {
      display: 'inline-block',
      border: 'none',
      lineHeight: 'inherit',
      minWidth: 'auto',
      minHeight: 'auto',
      width: 'auto',
      height: 'auto',
      // width: ['auto', 'auto', 'auto'],
      fontWeight: 'inherit',
      fontFamily: 'inherit',
      pt: 0,
      px: 0,
      p: 0,
      background: 'none',
      _disabled: {
        color: 'disabled',
        cursor: 'default',
      },
    },
    rust: {
      width: ['100%', null, 'auto'],
      minWidth: ['90px', '150px', '190px'],
      fontSize: ['sm', 'md'],
      py: [1, 3],
      textTransform: 'uppercase',
      minHeight: '1.8rem',
      bg: 'rust',
      color: 'sandstone',
      _hover: {
        bg: 'rustHover',
        color: 'tertiaryTextColor',
      },
      _disabled: {
        bg: 'disabled',
      },
    },
    heroCta: {
      minWidth: [null, null, '190px'],
      width: ['auto', '100%'],
      maxWidth: ['9.875rem', '17rem'],
      mt: [6, null, 10],
      px: [8, 'auto'],
      py: [4, 'auto'],
      bg: 'btnPrimaryBg',
      color: 'white',
      borderRadius: '4px',
      _hover: {
        bg: 'nightGreen',
      },
      _disabled: {
        border: 'disabled',
        color: 'white',
        bg: 'disabled',
        cursor: 'default',
      },
    },
    subscribeButton: {
      fontSize: '1rem',
      bg: 'subscribeBgButton',
      fontFamily: 'bold',
      borderRadius: '3rem',
      lineHeight: '1.5rem',
      color: 'subscribeTextButton',
      letterSpacing: '.15rem',
      textTransform: 'uppercase',
      boxShadow: 'subscribeButtonShadow',
      transition: 'all .4s ease-out',
      p: '.8rem 2rem',
      _hover: {
        bg: 'subscribeBgButtonHover',
        boxShadow: 'subscribeButtonShadowHover',
      },
    },
    faqButton: {
      fontSize: '1rem',
      bg: 'faqBgButton',
      fontFamily: 'bold',
      borderRadius: '3rem',
      lineHeight: '1.5rem',
      color: 'faqTextButton',
      letterSpacing: '.15rem',
      textTransform: 'uppercase',
      boxShadow: 'faqButtonShadow',
      transition: 'all .4s ease-out',
      p: '.8rem 2rem',
      _hover: {
        bg: 'faqBgButtonHover',
        boxShadow: 'faqButtonShadowHover',
      },
    },
    productSlideCta: {
      bgColor: ['btnSecondaryBg', 'transparent'],
      border: ['none', 'white'],
      color: 'white',
      size: 'md',
      fontWeight: 'bold',
      fontFamily: 'bold',
      width: '100%',
      minH: ['2.5rem', '2.75rem'],
      maxW: ['15rem'],
      px: ['1.25rem', '1.5rem'],
      py: ['0.5rem'],
      borderRadius: '3rem',
      textTransform: 'uppercase',
      letterSpacing: '0.05rem',
      _hover: {
        bgColor: ['nightGreen', 'white'],
        color: ['whiteSands', 'textPrimary'],
        shadow: 'lg',
      },
    },
  },
}
