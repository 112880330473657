import { AppActionCreator } from '../../redux/store'
import { CartDrawerActions } from './actionTypes'

export const openCartDrawer: AppActionCreator = () => ({
  type: CartDrawerActions.OPEN_CART_DRAWER,
})

export const closeCartDrawer: AppActionCreator = () => ({
  type: CartDrawerActions.CLOSE_CART_DRAWER,
})
